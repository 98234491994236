import React from 'react';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { apiLogIn } from '../api';
import TextField from '../components/form/TextField';

const LoginPage = props => (
  <div className="container">
    {props.errors.api && (
      <div className="notification is-danger">
        <button className="delete" />
        {props.errors.api}
      </div>
    )}
    <form className="columns" onSubmit={props.handleSubmit}>
      <div className="column">
        <Field name="username" label="Username" component={TextField} />
        <Field name="password" type="password" label="Password" component={TextField} />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button type="submit" className={`button is-link ${props.isSubmitting && 'is-loading'}`}>
            Log In
          </button>
        </div>
      </div>
    </form>
  </div>
);

export default withFormik({
  handleSubmit: ({ username, password }, { props, setErrors, setSubmitting }) => {
    props.logIn(username, password).catch(e => {
      setErrors({ api: 'Invalid username/password. Please try again.' });
      setSubmitting(false);
      console.error(e);
    });
  },
  mapPropsToValues: () => ({
    username: '',
    password: ''
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.')
  })
})(LoginPage);
