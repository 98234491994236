import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { updateUISettings } from '../actionCreators/index';
import RadioGroupField from '../components/form/RadioGroupField';
import ToggleField from '../components/form/ToggleField';

const rosterSortOptions = {
  ALPHABETICAL: 'Alphabetical',
  GRADE: 'Assessment Level'
};
const rosterSortOptionsSequence = ['ALPHABETICAL', 'GRADE'];
const candidateFilterOptions = {
  ALL: 'All Candidates',
  REGULAR_ONLY: 'Regular Candidates',
  WRITTEN_ONLY: 'Written-Only Candidates',
  PRACTICAL_ONLY: 'Practical-Only Candidates'
};
const candidateFilterOptionsSequence = ['ALL', 'REGULAR_ONLY', 'WRITTEN_ONLY', 'PRACTICAL_ONLY'];

const ClassUIOptionsPage = props => (
  <div className="container">
    <form className="columns" onSubmit={props.handleSubmit}>
      <div className="column">
        <Field
          name="rosterSort"
          label="Roster Sort Order"
          options={rosterSortOptions}
          optionsSequence={rosterSortOptionsSequence}
          component={RadioGroupField}
        />
        <Field name="showExams" label="Show Exams" component={ToggleField} />
        <Field name="showSchedule" label="Show Practical Exam Schedule" component={ToggleField} />
        <Field
          name="candidateFilter"
          label="Filter Candidates"
          options={candidateFilterOptions}
          optionsSequence={candidateFilterOptionsSequence}
          component={RadioGroupField}
        />
      </div>
      <div className="column" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button type="button" onClick={props.goBack} className="button is-info" style={{ marginRight: '20px' }}>
          Cancel
        </button>
        <button type="submit" className="button is-success">
          Confirm
        </button>
      </div>
    </form>
  </div>
);

const mapStateToProps = ({ ui }) => ({ ui });

const mapDispatchToProps = dispatch => bindActionCreators({ updateUISettings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    handleSubmit: (values, { props }) => {
      props.updateUISettings(values).then(() => {
        props.goBack();
      });
    },
    mapPropsToValues: ({ ui }) => ui,
    validationSchema: Yup.object().shape({})
  })(ClassUIOptionsPage)
);
