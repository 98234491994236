import _sortBy from 'lodash/sortBy';

export const getDeclinedTest = (declinedTests, crane) =>
  declinedTests.reduce((acc, declinedTest) => {
    if (declinedTest.crane === crane) {
      return declinedTest;
    }
    return acc;
  }, false);

export const parseScoreSheets = scoreSheetPhotos =>
  scoreSheetPhotos.reduce(
    (acc, scoreSheet) => {
      if (scoreSheet.page_num && scoreSheet.page_type) {
        const scoreSheetsPageEntry = acc.scoreSheets[scoreSheet.page_num];

        let updatedPageEntry = null;

        if (scoreSheetsPageEntry) {
          const scoreSheetsPageEntryType = scoreSheetsPageEntry[scoreSheet.page_type];

          let updatedPageEntryType = null;
          if (scoreSheetsPageEntryType) {
            updatedPageEntryType = [...scoreSheetsPageEntryType, scoreSheet];
          } else {
            updatedPageEntryType = [scoreSheet];
          }

          updatedPageEntry = {
            ...scoreSheetsPageEntry,
            [scoreSheet.page_type]: updatedPageEntryType
          };
        } else {
          updatedPageEntry = {
            [scoreSheet.page_type]: [scoreSheet]
          };
        }

        const updatedScoreSheetPages = acc.scoreSheetPages.includes(scoreSheet.page_num)
          ? acc.scoreSheetPages
          : _sortBy([...acc.scoreSheetPages, scoreSheet.page_num], pageNum => pageNum);

        return {
          scoreSheets: {
            ...acc.scoreSheets,
            [scoreSheet.page_num]: updatedPageEntry
          },
          scoreSheetPages: updatedScoreSheetPages
        };
      }

      return {
        scoreSheets: {
          ...acc.scoreSheets,
          UNSORTED: [...acc.scoreSheets.UNSORTED, scoreSheet]
        },
        scoreSheetPages: acc.scoreSheetPages
      };
    },
    {
      scoreSheets: {
        UNSORTED: []
      },
      scoreSheetPages: []
    }
  );
