import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { confirmDeclineTest } from '../../../actionCreators';
import CanvasField from '../../../components/form/CanvasField';
import CandidateInfoSection from './CandidateInfoSection';

class DeclinedTestConfirmPage extends Component {
  state = {
    hasSigned: false,
    isLoading: false,
    isFinished: false
  };

  handleConfirm = imageBase64 => {
    this.setState({ isLoading: true }, () => {
      const {
        confirmDeclineTest,
        candidate: { id },
        testSessionId,
        match: {
          params: { craneType }
        }
      } = this.props;

      confirmDeclineTest(id, testSessionId, craneType, imageBase64)
        .then(() => {
          this.setState({ isLoading: false, isFinished: true });
        })
        .catch(e => {
          console.error(e);
        });
    });
  };

  handleClickBack = () => {
    this.props.history.push(`/class/${this.props.testSessionId}`);
  };

  render() {
    const { props } = this;
    const currentLocalTime = moment.tz(new Date(), moment.tz.guess());
    const convertedTime = moment(currentLocalTime).tz('America/Los_Angeles');

    return (
      <section className="section--stop-training">
        <h4 style={{ fontWeight: 'bold', color: 'red' }}>
          By signing below, you are declining to take your scheduled Practical Exam.
        </h4>
        <CandidateInfoSection
          candidateName={props.candidate.name}
          currentLocalTime={currentLocalTime}
          convertedTime={convertedTime}
        />
        <CanvasField
          className="canvas-field--stop-training"
          handleConfirm={this.handleConfirm}
          isLoading={this.state.isLoading}
          isFinished={this.state.isFinished}
        />
        {this.state.isFinished && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '8px' }}>
            <div>
              <button type="buton" className="button is-success" onClick={this.handleClickBack}>
                Go Back
              </button>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirmDeclineTest
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(DeclinedTestConfirmPage);
