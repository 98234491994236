import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { S3_BUCKET_URL_CLASS_PHOTOS } from '../../lib/constants';
import { uploadTestSessionPhotoBase64 } from '../../actionCreators';
import PhotoCapture from '../../components/common/PhotoCapture';

class ClassPhotoRouter extends Component {
  handleCapturedPhoto = photoBase64 => {
    return this.props.uploadTestSessionPhotoBase64(this.props.classInfo.id, photoBase64, 'image/png');
  };

  render() {
    const { props } = this;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2>Class Photo</h2>
        <section className="modal-card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <PhotoCapture handleCapturedPhoto={this.handleCapturedPhoto} imgDefaultSrc={null} imgDefaultAlt={null} />
          {props.classInfo.testSessionPhotos.length > 0 && (
            <div style={{ marginTop: '20px', width: '100%' }}>
              <h4>Score Sheet Photos</h4>
              {props.classInfo.testSessionPhotos.map(testSessionPhoto => (
                <div key={testSessionPhoto.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <a
                    className="button is-link"
                    href={`${S3_BUCKET_URL_CLASS_PHOTOS}${testSessionPhoto.s3_key}`}
                    download
                    style={{ marginRight: '20px' }}
                  >
                    Download&nbsp;
                    <i className="fa fa-download" aria-hidden="true" />
                  </a>
                  <span style={{ fontStyle: 'italic' }}>
                    {moment.tz(testSessionPhoto.date_created, 'America/Los_Angeles').format('M-D-YYYY h:mm a z')}
                  </span>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadTestSessionPhotoBase64
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ClassPhotoRouter);
