import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { deleteTrainingSession } from '../../actionCreators';
import ActionButton from '../../components/common/ActionButton';

const typeStrMapping = {
  '5_MIN': '5 min. Pre-assessment',
  '15_MIN': '15 min. Familiarization',
  '20_MIN': '20 min. Training'
};

const getGradeComponent = grade => {
  switch (grade) {
    case 3: {
      return <button className="button is-success">Green</button>;
    }
    case 2: {
      return <button className="button is-warning">Yellow</button>;
    }
    case 1: {
      return <button className="button is-danger">Red</button>;
    }
    default: {
      return null;
    }
  }
};

class CandidateStatsRouter extends Component {
  handleDeleteButtonClick = data => {
    return this.props.deleteTrainingSession(this.props.candidate.id, parseInt(data.id, 10));
  };

  render() {
    const { props } = this;
    const { candidate, testSessionId, counterpartId } = props;
    const testSessionIds = [];

    if (testSessionId) {
      testSessionIds.push(parseInt(testSessionId, 10));
    }

    if (counterpartId) {
      testSessionIds.push(counterpartId);
    }

    const trainingSessions = candidate.trainingSessions.filter(trainingSession =>
      testSessionIds.includes(trainingSession.test_session_id)
    );

    return (
      <div>
        <h4 style={{ marginTop: '20px' }}>Training Sessions</h4>
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Grade</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {trainingSessions.map(trainingSession => {
              return (
                <tr key={trainingSession.id}>
                  <td>{moment(trainingSession.date_created).format('M/D/YYYY')}</td>
                  <td>{typeStrMapping[trainingSession.type]}</td>
                  <td>{getGradeComponent(trainingSession.grade)}</td>
                  <td>
                    <ActionButton
                      className="button is-danger"
                      handleClick={this.handleDeleteButtonClick}
                      buttonProps={{ 'data-id': trainingSession.id }}
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </ActionButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteTrainingSession
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CandidateStatsRouter);
