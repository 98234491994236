import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { S3_BUCKET_URL } from '../../lib/constants';
import { uploadPhotoBase64 } from '../../actionCreators';
import PhotoCapture from '../../components/common/PhotoCapture';

class CandidatePhotoRouter extends Component {
  handleCapturedPhoto = photoBase64 => {
    return this.props.uploadPhotoBase64(this.props.candidate.id, photoBase64, 'image/png');
  };

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2>Candidate Photo</h2>
        <PhotoCapture
          handleCapturedPhoto={this.handleCapturedPhoto}
          imgDefaultSrc={this.props.candidate.photoS3Key && `${S3_BUCKET_URL}${this.props.candidate.photoS3Key}`}
          imgDefaultAlt={this.props.candidate.name}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadPhotoBase64
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CandidatePhotoRouter);
