import { loadState } from './localStorage';

async function deleteCache() {
  const cacheKeys = await caches.keys();
  cacheKeys.forEach(async key => {
    await caches.delete(key);
  });
}

async function defaultUpgrade(_, to) {
  try {
    localStorage.removeItem('state');
    await deleteCache();
    localStorage.setItem('schemaVersion', to);
  } catch (e) {
    console.error(e);
  }

  return {};
}

const upgradeMapping = {
  '1.5': {
    '1.6': async (_, to) => {
      await deleteCache();
      try {
        localStorage.setItem('schemaVersion', to);
        return loadState();
      } catch (e) {
        console.error(e);
        return {};
      }
    }
  }
};

export default function upgradeSchema(from, to) {
  if (upgradeMapping[from] && upgradeMapping[from][to]) {
    return upgradeMapping[from][to](from, to);
  } else {
    return defaultUpgrade(from, to);
  }
}
