import React from 'react';

const RadioGroupField = ({ field, form: { touched, errors }, ...props }) => {
  const isTouched = touched[field.name];
  const hasErrors = !!errors[field.name];
  const errorText = errors[field.name];

  return (
    <div className="field">
      <label className="label" style={{ marginBottom: 0 }}>
        {props.label}
      </label>
      {isTouched &&
        hasErrors && (
          <p
            className="help is-danger animated fadeIn"
            style={{ backgroundColor: '#fff', padding: '2px', marginTop: 0 }}
          >
            {errorText}
          </p>
        )}
      <div className="control" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        {props.optionsSequence.map(key => (
          <label key={key} className="radio" style={{ marginLeft: '0.5em' }}>
            <input
              type="radio"
              name={field.name}
              value={key}
              onChange={field.onChange}
              checked={field.value == key}
              style={{ marginRight: '4px' }}
            />
            {props.options[key]}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroupField;
