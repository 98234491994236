import React, { Component, Fragment } from 'react';
import CanvasPreview from './PhotoCaptureSub/CanvasPreview';
import CameraPreview from './PhotoCaptureSub/CameraPreview';
import FileUpload from './PhotoCaptureSub/FileUpload';
import PhotoControls from './PhotoCaptureSub/PhotoControls';

const modes = {
  CAMERA: 'CAMERA',
  FILE: 'FILE',
  CAMERA_PREVIEW: 'CAMERA_PREVIEW',
  FILE_PREVIEW: 'FILE_PREVIEW',
  SAVED: 'SAVED'
};

const getDimensions = (videoWidth, videoHeight) => {
  const baseWidth = window.innerWidth - 48;
  const baseHeight = window.innerHeight;

  const shouldUseInnerWidth = videoWidth > baseWidth || videoHeight > baseHeight;

  if (shouldUseInnerWidth) {
    return {
      width: baseWidth,
      height: (videoHeight * baseWidth) / videoWidth
    };
  }

  return {
    width: videoWidth,
    height: videoHeight
  };
};

class PhotoCapture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      mode: props.imgDefaultSrc ? modes.SAVED : modes.CAMERA,
      isSaving: false,
      videoWidth: '',
      videoHeight: ''
    };
  }

  canvasRef = el => {
    this.canvas = el;
  };

  cameraPreviewRef = el => {
    this.cameraPreview = el;
  };

  fileUploadImgRef = el => {
    this.fileUploadImg = el;
  };

  accessCamera = () => {
    const constraints = { video: { facingMode: 'environment' } };

    try {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.cameraPreview.srcObject = stream;
        })
        .catch(e => {
          let errorMsg = '';
          if (e.name === 'NotFoundError') {
            errorMsg = 'No camera found. Please connect a camera to continue.';
          } else if (e.name === 'NotAllowedError') {
            errorMsg =
              'App does not have access to camera. Please go to your browser settings and allow access to https://cso.craneadmin.com/mugshot';
          } else {
            errorMsg =
              'App does not have access to camera. Please go to your browser settings and allow access to https://cso.craneadmin.com/mugshot';
          }

          this.setState({
            error: errorMsg,
            mode: modes.FILE
          });
        });
    } catch (e) {
      this.setState({
        error: 'Browser error - app cannot access device camera. Please use alternative photo upload options instead.',
        mode: modes.FILE
      });
    }
  };

  handleRotateClockwise = () => {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    context.rotate((90 * Math.PI) / 180);

    const refImg = this.state.mode === modes.CAMERA_PREVIEW ? this.cameraPreview : this.fileUploadImg;

    context.drawImage(refImg, 0, -1 * this.canvas.height, this.canvas.width, this.canvas.height);
  };

  handleRotateCounterClockwise = () => {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    context.rotate((-90 * Math.PI) / 180);

    const refImg = this.state.mode === modes.CAMERA_PREVIEW ? this.cameraPreview : this.fileUploadImg;

    context.drawImage(this.fileUploadImg, 0, 0, this.canvas.width, this.canvas.height);
  };

  handleCaptureButtonClick = () => {
    if (!this.cameraPreview.srcObject) {
      this.accessCamera();
    }

    const { width, height } = getDimensions(this.state.videoWidth, this.state.videoHeight);

    const context = this.canvas.getContext('2d');
    context.drawImage(this.cameraPreview, 0, 0, width, height);

    this.setState(
      {
        mode: modes.CAMERA_PREVIEW
      },
      () => {
        this.unmountCamera();
      }
    );
  };

  handleRetakeButtonClick = () => {
    if (!this.cameraPreview.srcObject) {
      this.accessCamera();
    }

    this.setState({
      mode: modes.CAMERA
    });
  };

  handleFileSelect = e => {
    this.fileUploadImg.src = URL.createObjectURL(e.currentTarget.files[0]);
    this.setState({ mode: modes.FILE_PREVIEW });
  };

  handleSaveButtonClick = () => {
    this.setState({ isSaving: true }, () => {
      const photoBase64 = this.canvas.toDataURL('image/png');
      this.props.handleCapturedPhoto(photoBase64).then(() => {
        this.setState({ isSaving: false });
      });
    });
  };

  setVideoDimensions = (videoWidth, videoHeight) => {
    this.setState({
      videoWidth,
      videoHeight
    });
  };

  unmountCamera = () => {
    if (this.cameraPreview && this.cameraPreview.srcObject) {
      const tracks = this.cameraPreview.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });

      this.cameraPreview.srcObject = null;
    }
  };

  componentDidMount() {
    if (this.state.mode === modes.CAMERA) {
      this.accessCamera();
    }
  }

  componentWillUnmount() {
    this.unmountCamera();

    if (this.img) {
      URL.revokeObjectURL(this.img.src);
    }
  }

  render() {
    const { mode, error } = this.state;
    const { width, height } = getDimensions(this.state.videoWidth, this.state.videoHeight);

    return (
      <Fragment>
        {error && <div>{error}</div>}
        {mode === 'SAVED' && (
          <div style={{ marginBottom: '20px' }}>
            <img src={this.props.imgDefaultSrc} alt={this.props.imgDefaultAlt} />
          </div>
        )}
        <CameraPreview
          mode={mode}
          cameraPreviewRef={this.cameraPreviewRef}
          cameraPreview={this.cameraPreview}
          setVideoDimensions={this.setVideoDimensions}
        />
        <CanvasPreview
          mode={mode}
          canvasRef={this.canvasRef}
          canvas={this.canvas}
          videoHeight={this.state.videoHeight}
          videoWidth={this.state.videoWidth}
          width={width}
          height={height}
          fileUploadImgRef={this.fileUploadImgRef}
          handleRotateClockwise={this.handleRotateClockwise}
          handleRotateCounterClockwise={this.handleRotateCounterClockwise}
        />
        <PhotoControls
          mode={mode}
          isSaving={this.state.isSaving}
          handleCaptureButtonClick={this.handleCaptureButtonClick}
          handleRetakeButtonClick={this.handleRetakeButtonClick}
          handleSaveButtonClick={this.handleSaveButtonClick}
          handleFileSelect={this.handleFileSelect}
        />
      </Fragment>
    );
  }
}

export default PhotoCapture;
