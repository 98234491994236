import React, { Fragment } from 'react';

const PhotoControls = props => {
  let controls = null;

  if (props.mode === 'CAMERA') {
    controls = (
      <button className="button is-success" onClick={props.handleCaptureButtonClick}>
        Take Photo
      </button>
    );
  }

  if (props.mode === 'CAMERA_PREVIEW') {
    controls = (
      <Fragment>
        <button className="button is-danger" style={{ marginRight: '20px' }} onClick={props.handleRetakeButtonClick}>
          Retake Photo
        </button>
        <button
          className={`button is-success ${props.isSaving ? 'is-loading' : ''}`}
          onClick={props.handleSaveButtonClick}
        >
          Save Photo
        </button>
      </Fragment>
    );
  }

  if (props.mode === 'FILE') {
    controls = (
      <div className="file">
        <label className="file-label">
          <input className="file-input" type="file" onChange={props.handleFileSelect} />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fa fa-upload" />
            </span>
            <span className="file-label">Upload Photo...</span>
          </span>
        </label>
      </div>
    );
  }

  if (props.mode === 'FILE_PREVIEW') {
    controls = (
      <Fragment>
        <div className="file" style={{ marginRight: '20px' }}>
          <label className="file-label">
            <input className="file-input" type="file" onChange={props.handleFileSelect} />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fa fa-upload" />
              </span>
              <span className="file-label">Change Photo...</span>
            </span>
          </label>
        </div>
        <button
          className={`button is-success ${props.isSaving ? 'is-loading' : ''}`}
          onClick={props.handleSaveButtonClick}
        >
          Save Photo
        </button>
      </Fragment>
    );
  }

  if (props.mode === 'SAVED') {
    controls = (
      <button className="button is-danger" onClick={props.handleRetakeButtonClick}>
        Retake Photo
      </button>
    );
  }

  return <div style={{ display: 'flex' }}>{controls}</div>;
};

export default PhotoControls;
