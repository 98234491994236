import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link, Route } from 'react-router-dom';
import { logOut, changeRosterSort, toggleShowExams, toggleShowSchedule } from '../actionCreators';

class Header extends Component {
  state = {
    mobileMenuOpen: false
  };

  getTitleFromUrl = () => {
    return 'CraneTrx';
  };

  toggleMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    });
  };

  handleSignOutClick = () => {
    this.props.logOut();
    this.props.history.push('/');
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    });
  };

  handleClick = e => {
    if (!this.navRootNode.contains(e.target)) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const inClassPage = /^\/class\/\d+$/.test(this.props.location.pathname);
    const pathArr = this.props.location.pathname.split('/');

    const backButtonComponent = () => (
      <div style={{ marginTop: '8px', marginLeft: '8px', marginRight: '8px' }}>
        <button
          type="button"
          className="button is-info"
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          Back
        </button>
      </div>
    );

    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
        style={{ marginBottom: '20px' }}
        ref={el => {
          this.navRootNode = el;
        }}
      >
        <div className="container">
          <div className="navbar-brand">
            <h1 className="title" style={{ margin: '8px 16px' }}>
              {this.getTitleFromUrl()}
            </h1>
            <div
              className={`navbar-burger ${this.state.mobileMenuOpen ? 'is-active' : ''}`}
              onClick={this.toggleMobileMenu}
            >
              <span />
              <span />
              <span />
            </div>
            <Route path="/class/:id/*" render={backButtonComponent} />
          </div>
          <div className={`navbar-menu ${this.state.mobileMenuOpen ? 'is-active' : ''}`}>
            <div className="navbar-end">
              {this.props.isLoggedIn ? (
                <Fragment>
                  <div className="navbar-item">
                    <div>
                      <i className="fa fa-user-circle" aria-hidden="true" />
                      &nbsp;
                      {`${this.props.user.firstName} ${this.props.user.lastName}`}
                    </div>
                  </div>
                  <div className="navbar-item">
                    <Link href="/" to="/">
                      <i className="fa fa-book" aria-hidden="true" />
                      &nbsp; Classes
                    </Link>
                  </div>
                  {inClassPage && (
                    <Fragment>
                      <div className="navbar-item">
                        <Link href="/options" to="/options" onClick={this.toggleMobileMenu}>
                          <i className="fa fa-cog" aria-hidden="true" />
                          &nbsp; Roster Options
                        </Link>
                      </div>
                      <div className="navbar-item">
                        <a href={`/api/test-session/zip?id=${pathArr[2]}`}>
                          <i className="fa fa-download" aria-hidden="true" />
                          &nbsp; Download Zip File
                        </a>
                      </div>
                      <div className="navbar-item">
                        <Link
                          href={`/class/${pathArr[2]}/site-report`}
                          to={`/class/${pathArr[2]}/site-report`}
                          onClick={this.toggleMobileMenu}
                        >
                          <i className="fa fa-check-square-o" aria-hidden="true" />
                          &nbsp; Site Report
                        </Link>
                      </div>
                      <div className="navbar-item">
                        <Link
                          href={`/class/${pathArr[2]}/photo`}
                          to={`/class/${pathArr[2]}/photo`}
                          onClick={this.toggleMobileMenu}
                        >
                          <i className="fa fa-camera" aria-hidden="true" />
                          &nbsp; Class Photos
                        </Link>
                      </div>
                    </Fragment>
                  )}
                  <div className="navbar-item">
                    <div onClick={this.handleSignOutClick}>
                      <i className="fa fa-sign-out" aria-hidden="true" />
                      &nbsp; Log Out
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="navbar-item">
                  <Link href="/" to="/" onClick={this.toggleMobileMenu}>
                    <i className="fa fa-sign-in" aria-hidden="true" />
                    &nbsp; Log In
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeRosterSort,
      toggleShowExams,
      toggleShowSchedule,
      logOut
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
