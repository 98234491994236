import _sortBy from 'lodash/sortBy';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUISettings } from '../../../actionCreators';
import StudentCard from '../../../components/StudentCard';
import OngoingTraining from './OngoingTraining';
import { summarizeTrainingSessions } from '../../../lib/trainingSessions';

const sortByGrade = (ids, candidates) => {
  return _sortBy(ids, id => {
    const candidate = candidates[id];
    const { grade } = summarizeTrainingSessions(candidate.trainingSessions);

    return grade * -1;
  });
};

const sortByName = (ids, candidates) => {
  return _sortBy(ids, id => {
    const candidate = candidates[id];
    return candidate.name.replace(' ', '').toLowerCase();
  });
};

const getOngoingTrainingSessions = (candidateIds, candidates) => {
  const ongoingTrainingSessions = candidateIds.reduce((acc, id) => {
    const candidate = candidates[id];

    if (candidate.trainingSessions && candidate.trainingSessions.length > 0) {
      const ongoingTraining = candidate.trainingSessions.reduce((tAcc, session) => {
        if (session.type === '20_MIN' && !session.attestation_s3_key) {
          return {
            id: candidate.id,
            name: candidate.name,
            startTime: session.start_time
          };
        }

        return tAcc;
      }, false);

      if (ongoingTraining) {
        return [...acc, ongoingTraining];
      }
    }

    return acc;
  }, []);

  return ongoingTrainingSessions;
};

const countExamTakers = (candidateIds, candidates, exam) =>
  candidateIds.reduce((acc, id) => {
    const candidate = candidates[id];
    return candidate.applicationFormSetup[exam] ? acc + 1 : acc;
  }, 0);

const countTotalPracticeHours = (candidateIds, candidates) =>
  candidateIds.reduce((acc, id) => {
    const candidate = candidates[id];

    if (candidate.practicalTestSchedules.length > 0) {
      const practiceHours = candidate.practicalTestSchedules.reduce((candidateAcc, { practice_hours: hours }) => {
        return hours ? candidateAcc + parseFloat(hours) : candidateAcc;
      }, 0);

      return acc + practiceHours;
    }

    return candidate.practiceTimeCredits ? parseFloat(candidate.practiceTimeCredits) + acc : acc;
  }, 0);

const checkHasWrittenExam = candidate => {
  const { coreExamEnabled, writtenSWEnabled, writtenFXEnabled } = candidate.applicationFormSetup;

  return coreExamEnabled || writtenSWEnabled || writtenFXEnabled;
};

const checkHasPracticalExam = candidate => {
  const { practicalSWEnabled, practicalFXEnabled } = candidate.applicationFormSetup;

  return practicalSWEnabled || practicalFXEnabled;
};

const filterRegularOnly = (ids, candidates) => {
  return ids.filter(id => {
    const candidate = candidates[id];
    return checkHasWrittenExam(candidate);
  });
};

const filterWrittenOnly = (ids, candidates) => {
  return ids.filter(id => {
    const candidate = candidates[id];
    const hasWritten = checkHasWrittenExam(candidate);
    const hasNoPractical = !checkHasPracticalExam(candidate);
    return hasWritten && hasNoPractical;
  });
};

const filterPracticalOnly = (ids, candidates) => {
  return ids.filter(id => {
    const candidate = candidates[id];
    const hasNoWritten = !checkHasWrittenExam(candidate);
    const hasPractical = checkHasPracticalExam(candidate);
    return hasNoWritten && hasPractical;
  });
};

class CandidateRosterPage extends Component {
  state = {
    detailsVisible: true
  };

  toggleDetails = () => {
    this.setState({ detailsVisible: !this.state.detailsVisible });
  };

  componentDidMount() {
    window.scrollTo(0, this.props.rosterLastYPos || 0);
  }

  componentWillUnmount() {
    this.props.rememberLastYPos();
  }

  render() {
    {
      const { props } = this;

      let ids = props.classInfo.candidates;

      const writtenCoreExamCount = countExamTakers(ids, props.candidates, 'coreExamEnabled');
      const writtenSWExamCount = countExamTakers(ids, props.candidates, 'writtenSWEnabled');
      const writtenFXExamCount = countExamTakers(ids, props.candidates, 'writtenFXEnabled');

      const practicalSWExamCount = countExamTakers(ids, props.candidates, 'practicalSWEnabled');
      const practicalFXExamCount = countExamTakers(ids, props.candidates, 'practicalFXEnabled');
      const totalPracticeHours = countTotalPracticeHours(ids, props.candidates);

      const ongoingTraining = getOngoingTrainingSessions(ids, props.candidates);

      if (props.candidateFilter === 'REGULAR_ONLY') {
        ids = filterRegularOnly(ids, props.candidates);
      }

      if (props.candidateFilter === 'WRITTEN_ONLY') {
        ids = filterWrittenOnly(ids, props.candidates);
      }

      if (props.candidateFilter === 'PRACTICAL_ONLY') {
        ids = filterPracticalOnly(ids, props.candidates);
      }

      if (props.rosterSort === 'ALPHABETICAL') {
        ids = sortByName(ids, props.candidates);
      }

      if (props.rosterSort === 'GRADE') {
        ids = sortByGrade(ids, props.candidates);
      }

      return (
        <div>
          <div style={{ position: 'sticky', top: '52px', zIndex: 1, display: 'flex' }}>
            <div className="card" style={{ width: '50%' }}>
              <header className="card-header">
                <div className="card-header-title">
                  <div>Class Summary</div>
                </div>
              </header>
              {this.state.detailsVisible && (
                <div className="card-content" style={{ padding: '8px' }}>
                  <div className="content" style={{ display: 'flex' }}>
                    <div style={{ marginRight: '40px' }}>
                      <div>Written Test</div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginRight: '20px' }}>
                          <div>Core Exam</div>
                          <div>SW Cab</div>
                          <div>FX Cab</div>
                        </div>
                        <div>
                          <div>{writtenCoreExamCount}</div>
                          <div>{writtenSWExamCount}</div>
                          <div>{writtenFXExamCount}</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>Practical Exam</div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginRight: '20px' }}>
                          <div>Practice Hours</div>
                          <div>SW Cab</div>
                          <div>FX Cab</div>
                        </div>
                        <div>
                          <div>{`${totalPracticeHours} hr(s).`}</div>
                          <div>{practicalSWExamCount}</div>
                          <div>{practicalFXExamCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <OngoingTraining
              ongoingTraining={ongoingTraining}
              toggleDetails={this.toggleDetails}
              detailsVisible={this.state.detailsVisible}
            />
          </div>
          {ids.map(candidateID => {
            const candidate = props.candidates[candidateID];

            return (
              <StudentCard
                key={candidate.id}
                testSessionId={props.id}
                testSessionCounterpartId={props.classInfo.counterpartId}
                candidate={candidate}
                history={props.history}
                match={props.match}
              />
            );
          })}
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      rememberLastYPos: () => updateUISettings({ rosterLastYPos: window.scrollY })
    },
    dispatch
  );

const mapStateToProps = ({ candidates, ui: { rosterSort, candidateFilter, rosterLastYPos } }) => {
  return { candidates, rosterSort, candidateFilter, rosterLastYPos };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CandidateRosterPage)
);
