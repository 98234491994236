import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

class ActionsButton extends Component {
  state = {
    isOpen: false
  };

  handleToggleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleScoreSheetPhotoClick = () => {
    this.setState({ isOpen: false });
    this.props.history.push(`${this.props.match.url}/candidate/${this.props.candidateId}/score-sheet-photo`);
  };

  handleTrainingPhotoClick = () => {
    this.setState({ isOpen: false });
    this.props.history.push(`${this.props.match.url}/candidate/${this.props.candidateId}/training-photo`);
  };

  handleStopTrainingClick = () => {
    this.setState({ isOpen: false });
    this.props.history.push(`${this.props.match.url}/candidate/${this.props.candidateId}/stop-training`);
  };

  handleDeclinedToTestConfirmClick = () => {
    this.setState({ isOpen: false });
    this.props.history.push(`${this.props.match.url}/candidate/${this.props.candidateId}/decline-test`);
  };

  handleViewStatsClick = () => {
    this.setState({ isOpen: false });
    this.props.history.push(`${this.props.match.url}/candidate/${this.props.candidateId}/view-stats`);
  };

  render() {
    return (
      <div
        className={`dropdown is-right ${this.state.isOpen ? 'is-active' : ''}`}
        style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
        onBlur={e => {
          const clicked = e.relatedTarget && e.relatedTarget.classList.contains('dropdown-item');
          if (clicked) {
          } else {
            this.setState({ isOpen: false });
          }
        }}
      >
        <div className="dropdown-trigger">
          <button
            className="button"
            onClick={this.handleToggleClick}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            style={{ backgroundColor: '#3273dc', color: '#fff', borderColor: 'transparent' }}
          >
            <span>More Actions</span>
            <span className="icon is-small">
              <i className="fa fa-angle-down" aria-hidden="true" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <button
              onClick={this.handleScoreSheetPhotoClick}
              className="dropdown-item"
              style={{ background: 'none', border: 'none' }}
            >
              Score Sheet Photo
            </button>
            <hr className="dropdown-divider" />
            <button
              onClick={this.handleViewStatsClick}
              className="dropdown-item"
              style={{ background: 'none', border: 'none' }}
            >
              View Stats/Training
            </button>
            <hr className="dropdown-divider" />
            <button
              onClick={this.handleTrainingPhotoClick}
              className="dropdown-item"
              style={{ background: 'none', border: 'none' }}
            >
              Capture Training Photo
            </button>
            <hr className="dropdown-divider" />
            {!this.props.freeTrainingComplete && (
              <Fragment>
                <button
                  onClick={this.handleStopTrainingClick}
                  className="dropdown-item"
                  style={{ background: 'none', border: 'none' }}
                >
                  Complete Free Training
                </button>
                <hr className="dropdown-divider" />
              </Fragment>
            )}
            <button
              onClick={this.handleDeclinedToTestConfirmClick}
              className="dropdown-item"
              style={{ background: 'none', border: 'none' }}
            >
              Declined to Test: Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActionsButton);
