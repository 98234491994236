import React, { Component } from 'react';

class ActionButton extends Component {
  state = {
    isLoading: false
  };

  handleClick = e => {
    const data = Object.assign({}, e.currentTarget.dataset);

    this.setState({ isLoading: true }, () => {
      this.props
        .handleClick(data)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    return (
      <button
        className={`${this.props.className} ${this.state.isLoading ? 'is-loading' : ''}`}
        type="button"
        onClick={this.handleClick}
        {...this.props.buttonProps}
      >
        {this.props.children}
      </button>
    );
  }
}

export default ActionButton;
