export function deriveNextPageAndType(scoreSheets, scoreSheetPages) {
  return scoreSheetPages.reduce(
    (acc, page) => {
      if (page > acc.nextPage) {
        return acc;
      }

      const scoreSheet = scoreSheets[page];
      const frontPages = scoreSheet['FRONT'];
      const backPages = scoreSheet['BACK'];

      if (!frontPages || frontPages.length === 0) {
        return {
          nextPage: page,
          nextPageType: 'FRONT'
        };
      }

      if (!backPages || backPages.length === 0) {
        return {
          nextPage: page,
          nextPageType: 'BACK'
        };
      }

      return acc;
    },
    {
      nextPage: scoreSheetPages.slice(-1)[0] + 1 || 1,
      nextPageType: 'FRONT'
    }
  );
}
