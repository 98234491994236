import React from 'react';

const CanvasPreview = props => {
  return (
    <div
      style={{
        display: props.mode === 'CAMERA_PREVIEW' || props.mode === 'FILE_PREVIEW' ? 'block' : 'none',
        marginBottom: '8px'
      }}
    >
      <div style={{ width: '100%' }}>
        <canvas ref={props.canvasRef} width={props.width} height={props.height} />
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <button className="button is-info" onClick={props.handleRotateClockwise}>
          <i className="fa fa-undo" aria-hidden="true" />
        </button>
        <button className="button is-info" onClick={props.handleRotateCounterClockwise}>
          <i className="fa fa-repeat" aria-hidden="true" />
        </button>
      </div> */}
      <div style={{ display: 'none' }}>
        <img
          onLoad={e => {
            const context = props.canvas.getContext('2d');
            context.drawImage(e.currentTarget, 0, 0, props.videoWidth, props.videoHeight);
          }}
          ref={props.fileUploadImgRef}
        />
      </div>
    </div>
  );
};

export default CanvasPreview;
