import _sortBy from 'lodash/sortBy';

const dialogDefaultState = {
  isOpen: false,
  type: 'NONE',
  testSessionId: '',
  candidateId: ''
};

const uiDefaultState = {
  rosterSort: 'ALPHABETICAL',
  rosterLastYPos: 0,
  allClassDayFilter: 30,
  previousClassDayFilter: 30,
  showExams: true,
  showSchedule: true,
  candidateFilter: 'ALL'
};

export const defaultState = {
  isLoggedIn: false,
  classes: {},
  candidates: {},
  dialog: dialogDefaultState,
  ui: uiDefaultState,
  applicationTypes: [],
  ongoingTraining: {}
};

export const dialogTypes = {
  NONE: 'NONE',
  ADD_PRACTICE_TIME: 'ADD_PRACTICE_TIME',
  PHOTO: 'PHOTO',
  START_TRAINING: 'START_TRAINING',
  STOP_TRAINING: 'STOP_TRAINING',
  CLASS_PHOTO: 'CLASS_PHOTO',
  REGISTER_WALK_IN: 'REGISTER_WALK_IN'
};

const UserReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_OTHER_CLASS_LIST': {
      return {
        ...state,
        otherClasses: action.payload
      };
    }
    case 'UPDATE_PREVIOUS_CLASS_LIST': {
      return {
        ...state,
        previousClasses: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

const CandidateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_CANDIDATE': {
      return {
        ...state,
        ...action.payload.candidate
      };
    }
    case 'UPDATE_CANDIDATE': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'UPDATE_CANDIDATE_SCORE_SHEET': {
      return {
        ...state,
        scoreSheetPhotos: [...state.scoreSheetPhotos, action.payload.scoreSheetPhoto]
      };
    }
    case 'DELETE_CANDIDATE_SCORE_SHEET': {
      return {
        ...state,
        scoreSheetPhotos: state.scoreSheetPhotos.filter(({ id }) => {
          return id !== action.payload.scoreSheetId;
        })
      };
    }
    case 'ADD_PENDING_TRANSACTION': {
      return {
        ...state,
        pendingTransactions: [...state.pendingTransactions, action.payload.pendingTransaction]
      };
    }
    case 'ADD_TRAINING_SESSION': {
      return {
        ...state,
        trainingSessions: [action.payload.trainingSession, ...state.trainingSessions]
      };
    }
    case 'UPDATE_TRAINING_SESSION': {
      const updatedTrainingSession = {
        ...action.payload.trainingSession,
        test_session_id: parseInt(action.payload.trainingSession.test_session_id, 10)
      };

      const newTrainingSessions = state.trainingSessions.map(trainingSession => {
        if (trainingSession.id === updatedTrainingSession.id) {
          return updatedTrainingSession;
        }
        return trainingSession;
      });

      const updatedTrainingSessionIndex = newTrainingSessions.findIndex(tr => tr.id === updatedTrainingSession.id);
      if (updatedTrainingSessionIndex === -1) {
        newTrainingSessions.push(updatedTrainingSession);
      }

      return {
        ...state,
        trainingSessions: newTrainingSessions
      };
    }
    case 'DELETE_TRAINING_SESSION': {
      const newTrainingSessions = state.trainingSessions.filter(tr => tr.id !== action.payload.trainingSessionId);

      return {
        ...state,
        trainingSessions: newTrainingSessions
      };
    }
    case 'CONFIRM_DECLINE_TEST': {
      return {
        ...state,
        declinedTests: [...state.declinedTests, action.payload.declinedTest]
      };
    }
    default: {
      return state;
    }
  }
};

const UIReducer = (state = uiDefaultState, action) => {
  switch (action.type) {
    case 'UPDATE_UI_SETTINGS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'CHANGE_ROSTER_SORT': {
      return {
        ...state,
        rosterSort: action.payload
      };
    }
    case 'CHANGE_ALL_CLASS_DAY_FILTER': {
      return {
        ...state,
        allClassDayFilter: action.payload
      };
    }
    case 'CHANGE_PREVIOUS_CLASS_DAY_FILTER': {
      return {
        ...state,
        previousClassDayFilter: action.payload
      };
    }
    case 'TOGGLE_SHOW_EXAMS': {
      return {
        ...state,
        showExams: !state.showExams
      };
    }
    case 'TOGGLE_SHOW_SCHEDULE': {
      return {
        ...state,
        showSchedule: !state.showSchedule
      };
    }
    case 'CHANGE_CANDIDATE_FILTER': {
      return {
        ...state,
        candidateFilter: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export const RootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOG_IN': {
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true
      };
    }
    case 'LOG_OUT': {
      return defaultState;
    }
    case 'UPDATE_USER': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }
    case 'UPDATE_APPLICATION_TYPES': {
      return {
        ...state,
        applicationTypes: action.payload
      };
    }
    case 'ADD_CANDIDATE': {
      const { testSessionId } = action.payload;
      const testSession = state.classes[testSessionId];
      const { candidate } = action.payload;

      const newCandidates = {
        ...state.candidates,
        [candidate.id]: CandidateReducer(undefined, action)
      };

      const newClasses = {
        ...state.classes,
        [testSessionId]: {
          ...testSession,
          candidates: _sortBy([...testSession.candidates, candidate.id], candidateId => newCandidates[candidateId].name)
        }
      };

      return {
        ...state,
        classes: newClasses,
        candidates: newCandidates
      };
    }
    case 'CONFIRM_DECLINE_TEST':
    case 'ADD_PENDING_TRANSACTION':
    case 'ADD_TRAINING_SESSION':
    case 'UPDATE_TRAINING_SESSION':
    case 'DELETE_TRAINING_SESSION':
    case 'UPDATE_CANDIDATE_SCORE_SHEET':
    case 'DELETE_CANDIDATE_SCORE_SHEET':
    case 'UPDATE_CANDIDATE': {
      return {
        ...state,
        candidates: {
          ...state.candidates,
          [action.payload.id]: CandidateReducer(state.candidates[action.payload.id], action)
        }
      };
    }
    case 'UPDATE_CLASS': {
      const newClass = {
        id: action.payload.class.id,
        counterpartId: action.payload.class.counterpartId,
        testSessionPhotos: action.payload.class.testSessionPhotos,
        candidates: action.payload.class.candidates.map(({ id }) => id)
      };
      const newCandidates = action.payload.class.candidates.reduce(
        (acc, candidate) => ({
          ...acc,
          [candidate.id]: candidate
        }),
        state.candidates
      );

      return {
        ...state,
        classes: {
          ...state.classes,
          [action.payload.class.id]: newClass
        },
        candidates: newCandidates
      };
    }
    case 'UPDATE_OTHER_CLASS_LIST':
    case 'UPDATE_PREVIOUS_CLASS_LIST': {
      return {
        ...state,
        user: UserReducer(state.user, action)
      };
    }
    case 'OPEN_DIALOG': {
      return {
        ...state,
        dialog: {
          isOpen: true,
          type: action.payload.type,
          testSessionId: action.payload.testSessionId,
          candidateId: action.payload.candidateId
        }
      };
    }
    case 'CLOSE_DIALOG': {
      return {
        ...state,
        dialog: dialogDefaultState
      };
    }
    case 'UPDATE_UI_SETTINGS':
    case 'CHANGE_CANDIDATE_FILTER':
    case 'TOGGLE_SHOW_SCHEDULE':
    case 'TOGGLE_SHOW_EXAMS':
    case 'CHANGE_ROSTER_SORT':
    case 'CHANGE_ALL_CLASS_DAY_FILTER':
    case 'CHANGE_PREVIOUS_CLASS_DAY_FILTER': {
      return {
        ...state,
        ui: UIReducer(state.ui, action)
      };
    }
    default: {
      return state;
    }
  }
};
