import React from 'react';

const ToggleFieldLogged = ({ field, form, label, style, inputStyle, logger }) => (
  <div className="field" style={{ display: 'flex', alignItems: 'center', ...style }}>
    <input
      id={field.name}
      type="checkbox"
      className="switch is-rounded is-info"
      name={field.name}
      checked={field.value}
      onBlur={field.onBlur}
      onChange={e => {
        form.setFieldValue(field.name, !field.value);
        logger(field.name, !field.value);
      }}
      style={{ marginRight: '4px', ...inputStyle }}
    />
    <label htmlFor={field.name} style={{ fontWeight: 'bold' }}>
      {label}
    </label>
  </div>
);

export default ToggleFieldLogged;
