import React from 'react';

function CandidateInfoSection({ candidateName, currentLocalTime, convertedTime, style }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', ...style }}>
      <div style={{ display: 'flex' }}>
        <div style={{ textAlign: 'right', marginRight: '10px' }}>
          <h4>Name:</h4>
          <div>Date:</div>
          <div>Time (Local):</div>
          <div>Time (Main Office):</div>
        </div>
        <div>
          <h4>{candidateName}</h4>
          <div>{currentLocalTime.format('M/D/YYYY')}</div>
          <div>{currentLocalTime.format('h:mm a z')}</div>
          <div>{convertedTime.format('h:mm a z')}</div>
        </div>
      </div>
    </div>
  );
}

export default CandidateInfoSection;
