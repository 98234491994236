import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import LineItemsSection from './LineItemsSection';
import { paymentTypes } from '../../../lib/candidateTransactions';
import { postPendingTransaction } from '../../../actionCreators';
import TextField from '../../../components/form/TextField';
import SelectField from '../../../components/form/SelectField';

class ReceivePaymentRouter extends Component {
  render() {
    const { props } = this;

    return (
      <div>
        <h2>Receive Payment</h2>
        <div>
          <div>
            <Field name="type" label="Payment Type" options={paymentTypes} component={SelectField} />
            {props.values.lineItems.length < 1 && <Field name="amount" label="Amount" component={TextField} />}
            {props.values.type == 2 && <Field name="checkNumber" label="Check Number" component={TextField} />}
            <LineItemsSection values={props.values} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className={`button is-success ${props.isSubmitting ? 'is-loading' : ''}`}
                onClick={props.handleSubmit}
              >
                Receive Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postPendingTransaction
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(
  withFormik({
    handleSubmit: (values, { props }) => {
      const details = {
        type: parseInt(values.type, 10)
      };

      if (values.type == 2 && values.checkNumber) {
        details.checkNumber = values.checkNumber;
      }

      if (values.lineItems.length > 0) {
        const { lineItems, totalAmount } = values.lineItems.reduce(
          (acc, { description, amount }) => {
            if (description && amount) {
              return {
                lineItems: [...acc.lineItems, { description, amount }],
                totalAmount: parseFloat(amount) + acc.totalAmount
              };
            }
            return acc;
          },
          {
            lineItems: [],
            totalAmount: 0
          }
        );

        if (lineItems.length > 0) {
          details.lineItems = lineItems;
        }

        details.amount = totalAmount;
      } else {
        details.amount = values.amount;
      }

      props
        .postPendingTransaction(props.candidate.id, details)
        .then(() => {
          props.history.goBack();
        })
        .catch(e => {
          console.error(e);
        });
    },
    mapPropsToValues: () => ({
      type: '',
      amount: '',
      checkNumber: '',
      authCode: '',
      txId: '',
      lineItems: []
    }),
    validationSchema: Yup.object().shape({
      type: Yup.mixed()
        .oneOf(Object.keys(paymentTypes))
        .required('Payment Type is required.'),
      amount: Yup.number().when('lineItems', (lineItems, schema) => {
        if (lineItems.length < 1) {
          return schema.moreThan(0, 'Amount must be greater than $0.00.').required('Amount is required.');
        }
        return schema;
      }),
      lineItems: Yup.mixed()
    })
  })(ReceivePaymentRouter)
);
