import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { uploadScoreSheetBase64, deleteScoreSheet } from '../../actionCreators';
import IndexPage from './ScoreSheetPhoto/IndexPage';
import PhotoCapturePage from './ScoreSheetPhoto/PhotoCapturePage';

class ScoreSheetPhotoRouter extends Component {
  handleCapturedPhoto = (photoBase64, pageNum, pageType) => {
    return this.props.uploadScoreSheetBase64(
      this.props.candidate.id,
      this.props.testSessionId,
      photoBase64,
      'image/png',
      pageNum,
      pageType
    );
  };

  handleDeleteButtonClick = id => {
    return this.props.deleteScoreSheet(id, this.props.candidate.id);
  };

  render() {
    const { props } = this;

    return (
      <Fragment>
        <Route
          exact
          path={`${props.match.url}/`}
          render={routeProps => (
            <IndexPage
              candidate={props.candidate}
              handleDeleteButtonClick={this.handleDeleteButtonClick}
              {...routeProps}
            />
          )}
        />
        <Route
          path={`${props.match.url}/capture/:pageNum/:pageType`}
          render={routeProps => <PhotoCapturePage handleCapturedPhoto={this.handleCapturedPhoto} {...routeProps} />}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadScoreSheetBase64,
      deleteScoreSheet
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ScoreSheetPhotoRouter);
