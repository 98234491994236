import gql from 'graphql-tag';
import { print } from 'graphql';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const generateGqlArguments = argsObj => {
  const argsArr = Object.keys(argsObj).map(key => {
    if (argsObj[key] === null) {
      return '';
    }

    const start = Array.isArray(argsObj) ? '' : `${key}: `;

    if (typeof argsObj[key] === 'string') {
      return `${start}"${argsObj[key]}"`;
    }

    if (typeof argsObj[key] === 'number' || typeof argsObj[key] === 'boolean') {
      return `${start}${argsObj[key]}`;
    }

    if (typeof argsObj[key] === 'object') {
      if (Array.isArray(argsObj[key])) {
        return `${start}[ ${generateGqlArguments(argsObj[key]).join(', ')} ]`;
      }

      return `${start}{ ${generateGqlArguments(argsObj[key])} }`;
    }
  });

  return argsArr.join(', ');
};

const getLocation = () =>
  new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            lat: position.coords.latitude,
            long: position.coords.longitude
          });
        },
        err => {
          reject(err);
        }
      );
    } else {
      reject(new Error('Geolocation not available'));
    }
  });

export const apiGqlUserLog = async payload => {
  let locationResult = undefined;
  let token = undefined;

  try {
    locationResult = await getLocation();
    token = localStorage.getItem('token');
  } catch (e) {
    console.error(e);
    return;
  }

  const args = {
    userLog: {
      ...payload,
      source: 'CRANE_TRX',
      location: {
        lat: locationResult.lat.toString(),
        long: locationResult.long.toString()
      }
    }
  };

  const argsStr = generateGqlArguments(args);

  const query = gql`
  mutation {
    addUserLog(${argsStr}) {
      id
    }
  }
  `;

  return axios.post(SERVER_URL, {
    query: print(query),
    token
  });
};

export const apiGqlSignIn = async (username, password) => {
  const query = gql`
  mutation {
    signIn(username: "${username}", password: "${password}")
  }
  `;

  let locationResult = undefined;

  try {
    locationResult = await getLocation();
  } catch (e) {}

  return axios.post(SERVER_URL, {
    query: print(query),
    location: { lat: locationResult.lat, long: locationResult.long }
  });
};

export const apiGqlSignOut = async () => {
  const query = gql`
    mutation {
      signOut
    }
  `;

  let locationResult = undefined;
  let token = undefined;

  try {
    locationResult = await getLocation();
    token = localStorage.getItem('token');
  } catch (e) {
    console.error(e);
    return;
  }

  return axios.post(SERVER_URL, {
    query: print(query),
    location: { lat: locationResult.lat, long: locationResult.long },
    token
  });
};

export const apiGqlGetSiteReport = async testSessionId => {
  const args = {
    id: testSessionId
  };

  const argsStr = generateGqlArguments(args);

  const query = gql`
  {
    testSession(${argsStr}) {
      siteReport {
        values {
          key
          value
        }
      }
    }
  }
  `;

  return axios.post(SERVER_URL, {
    query: print(query)
  });
};

export const apiGqlUpdateSiteReport = async (testSessionId, reportValue) => {
  let locationResult = undefined;
  let token = undefined;

  try {
    locationResult = await getLocation();
    token = localStorage.getItem('token');
  } catch (e) {
    console.error(e);
    return;
  }

  const args = {
    testSessionId,
    reportValue
  };

  const argsStr = generateGqlArguments(args);

  const query = gql`
  mutation {
    updateSiteReport(${argsStr}) {
      id
      values {
        key
        value
      }
    }
  }
  `;

  return axios.post(SERVER_URL, {
    query: print(query),
    location: {
      lat: locationResult.lat,
      long: locationResult.long
    },
    token
  });
};
