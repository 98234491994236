import React, { Fragment } from 'react';
import { Route } from 'react-router';
import DeclinedTestSelectPage from './DeclinedTestSelectPage';
import DeclinedTestConfirmPage from './DeclinedTestConfirmPage';

const DeclinedTestRouter = props => (
  <Fragment>
    <Route
      exact
      path={`${props.match.url}/`}
      render={routeProps => <DeclinedTestSelectPage candidate={props.candidate} {...routeProps} />}
    />
    <Route
      path={`${props.match.url}/confirm/:craneType`}
      render={routeProps => (
        <DeclinedTestConfirmPage testSessionId={props.testSessionId} candidate={props.candidate} {...routeProps} />
      )}
    />
  </Fragment>
);

export default DeclinedTestRouter;
