import { formatMoney } from 'accounting';
import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import LineItem from './LineItem';

const LineItemsSection = props => {
  return (
    <div>
      <FieldArray
        name="lineItems"
        render={arrayHelpers => {
          const AddBtn = (
            <button className={`button is-link`} onClick={() => arrayHelpers.push({ description: '', amount: '' })}>
              Add Line Item
            </button>
          );

          const totalAmount = props.values.lineItems.reduce((acc, lineItem) => {
            return lineItem.amount ? acc + parseFloat(lineItem.amount) : acc;
          }, 0);

          return (
            <Fragment>
              <hr />
              {props.values.lineItems.length > 0 ? (
                <Fragment>
                  <div>Line Items</div>
                  <table className="table is-bordered is-hoverable is-fullwidth" style={{ marginBottom: '1rem' }}>
                    <thead>
                      <tr>
                        <th style={{ width: '60%' }}>Description</th>
                        <th style={{ width: '20%' }}>Item Amount</th>
                        <th style={{ border: 'none', width: '20%' }} />
                      </tr>
                    </thead>
                    <tbody>
                      {props.values.lineItems.map((lineItem, index) => (
                        <LineItem key={index} index={index} arrayHelpers={arrayHelpers} />
                      ))}
                    </tbody>
                  </table>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{AddBtn}</div>
                    <div>
                      <table className="table is-bordered is-hoverable">
                        <tbody>
                          <tr>
                            <td>Total Amount</td>
                            <td>{formatMoney(totalAmount)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Fragment>
              ) : (
                AddBtn
              )}
            </Fragment>
          );
        }}
      />
    </div>
  );
};

export default LineItemsSection;
