import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { apiGetClassInfo } from '../api';
import CandidateRosterPage from './AdminClass/CandidateRosterPage';
import ClassPhotoRouter from './AdminClass/ClassPhotoRouter';
import SiteReportRouter from './AdminClass/SiteReportRouter';
import CandidatePhotoRouter from './AdminClass/CandidatePhotoRouter';
import ReceivePaymentRouter from './AdminClass/ReceivePaymentRouter';
import AddPracticeTimeRouter from './AdminClass/AddPracticeTimeRouter';
import ScoreSheetPhotoRouter from './AdminClass/ScoreSheetPhotoRouter';
import CandidateStatsRouter from './AdminClass/CandidateStatsRouter';
import TrainingPhotoRouter from './AdminClass/TrainingPhotoRouter';
import StopTrainingRouter from './AdminClass/StopTrainingRouter';
import DeclinedTestRouter from './AdminClass/DeclinedTestRouter';

class AdminClassContainer extends Component {
  componentDidMount() {
    apiGetClassInfo(this.props.id).then(({ data }) => {
      this.props.updateClass(data);
    });
  }

  render() {
    return (
      <div className="container">
        {this.props.classInfo ? (
          <Switch>
            <Route
              path={this.props.match.url}
              exact
              render={() => <CandidateRosterPage classInfo={this.props.classInfo} id={this.props.id} />}
            />
            <Route
              path={`${this.props.match.url}/photo`}
              render={() => <ClassPhotoRouter classInfo={this.props.classInfo} id={this.props.id} />}
            />
            <Route
              path={`${this.props.match.url}/site-report`}
              render={() => (
                <SiteReportRouter
                  testSessionId={this.props.id}
                  userId={this.props.userId}
                  classInfo={this.props.classInfo}
                  id={this.props.id}
                  openDialog={this.props.openDialog}
                />
              )}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/photo`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return <CandidatePhotoRouter testSessionId={this.props.id} candidate={candidate} {...props} />;
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/receive-payment`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return <ReceivePaymentRouter testSessionId={this.props.id} candidate={candidate} {...props} />;
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/add-practice-time`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return <AddPracticeTimeRouter testSessionId={this.props.id} candidate={candidate} {...props} />;
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/score-sheet-photo`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return <ScoreSheetPhotoRouter testSessionId={this.props.id} candidate={candidate} {...props} />;
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/view-stats`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return (
                  <CandidateStatsRouter
                    testSessionId={this.props.id}
                    counterpartId={this.props.classInfo.counterpartId}
                    candidate={candidate}
                    {...props}
                  />
                );
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/training-photo`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return (
                  <TrainingPhotoRouter
                    testSessionId={this.props.id}
                    counterpartId={this.props.classInfo.counterpartId}
                    candidate={candidate}
                    {...props}
                  />
                );
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/stop-training`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return (
                  <StopTrainingRouter
                    testSessionId={this.props.id}
                    candidate={candidate}
                    trainingSession={this.props.ongoingTraining[candidate.id]}
                    {...props}
                  />
                );
              }}
            />
            <Route
              path={`${this.props.match.url}/candidate/:candidateId/decline-test`}
              render={props => {
                const candidate = this.props.candidates[props.match.params.candidateId];
                return <DeclinedTestRouter testSessionId={this.props.id} candidate={candidate} {...props} />;
              }}
            />
          </Switch>
        ) : (
          <button className="button is-link is-loading">Loading</button>
        )}
      </div>
    );
  }
}

export default AdminClassContainer;
