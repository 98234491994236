import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ClassListTable = props => (
  <table className="table" style={{ width: '100%' }}>
    <thead>
      <tr>
        <th>Class</th>
        {!props.noStatus && <th>Status</th>}
        <th />
      </tr>
    </thead>
    <tbody>
      {props.classes.map(c => {
        return (
          <tr key={c.id}>
            <td>
              {c.session_number}
              <br />
              {moment(c.start_date).format('MMM D')}
            </td>
            {!props.noStatus && <td>{props.status}</td>}
            <td>
              <Link className="button is-link" href={`/class/${c.id}`} to={`/class/${c.id}`} style={{ margin: '10px' }}>
                Go to Class
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default ClassListTable;
