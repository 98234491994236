export function summarizeTrainingSessions(trainingSessions) {
  const result = trainingSessions.reduce(
    (acc, trainingSession) => {
      const currentResult = {
        inTraining: false,
        grade: 0,
        assessmentComplete: false,
        familiarizationComplete: false
      };

      if (trainingSession.start_time && !trainingSession.end_time) {
        currentResult.inTraining = true;
      }

      if (trainingSession.type === '5_MIN') {
        currentResult.grade = trainingSession.grade;
      }

      if (trainingSession.type === '15_MIN' && !!trainingSession.attestation_s3_key) {
        currentResult.familiarizationComplete = true;
      }

      if (trainingSession.type === '20_MIN') {
        if (!!trainingSession.attestation_s3_key) {
          currentResult.familiarizationComplete = true;
          currentResult.familiarizationOngoing = false;
        } else {
          currentResult.familiarizationComplete = false;
          currentResult.familiarizationOngoing = true;
        }
      }

      return {
        inTraining: acc.inTraining || currentResult.inTraining,
        grade: acc.grade || currentResult.grade,
        assessmentComplete: acc.assessmentComplete || !!currentResult.grade,
        familiarizationOngoing: acc.familiarizationOngoing || currentResult.familiarizationOngoing,
        familiarizationComplete: acc.familiarizationComplete || currentResult.familiarizationComplete
      };
    },
    {
      inTraining: false,
      grade: 0,
      assessmentComplete: false,
      familiarizationOngoing: false,
      familiarizationComplete: false
    }
  );

  return result;
}

export function checkIfFamiliarizationComplete(trainingSessions) {
  const result = trainingSessions.reduce((acc, trainingSession) => {
    if (trainingSession.type === '5_MIN' && !!trainingSession.attestation_s3_key) {
      return trainingSession.grade || acc;
    }
    return acc;
  }, 0);

  return result;
}
