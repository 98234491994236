import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import TextField from '../../components/form/TextField';
import RadioGroupField from '../../components/form/RadioGroupField';
import ToggleFieldLogged from '../../components/form/ToggleFieldLogged';
import { apiGqlGetSiteReport, apiGqlUpdateSiteReport } from '../../api/gql';

const craneTypeOptions = {
  BOOM_TRUCK: 'Lattice Boom Truck',
  BOOM_CRAWLER: 'Lattice Boom Crawler',
  SWING_CAB: 'Telescopic Boom - Swing Cab',
  FIXED_CAB: 'Telescopic Boom - Fixed Cab'
};

const craneTypeOptionsSequence = ['BOOM_TRUCK', 'BOOM_CRAWLER', 'SWING_CAB', 'FIXED_CAB'];

const SiteReportCheckBox = props => (
  <Field
    name={props.name}
    label={props.label}
    component={ToggleFieldLogged}
    style={{ alignItems: 'flex-start' }}
    inputStyle={{ marginTop: '6px' }}
    logger={(key, value) => {
      apiGqlUpdateSiteReport(props.testSessionId, { key, value });
    }}
  />
);

const Heading = ({ children }) => <div style={{ fontWeight: 'bold' }}>{children}</div>;

class SiteReportRouter extends Component {
  async componentDidMount() {
    try {
      const {
        data: {
          data: {
            testSession: { siteReport }
          }
        }
      } = await apiGqlGetSiteReport(this.props.testSessionId);

      if (!siteReport) {
        return;
      }

      const newValues = siteReport.values.reduce(
        (acc, { key, value }) => ({
          ...acc,
          [key]: value
        }),
        this.props.values
      );

      this.props.setValues(newValues);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <div>
        <h3 style={{ fontWeight: 'bold' }}>Site Report</h3>
        <h4>PRACTICAL EXAMINATION—MOBILE CRANES</h4>
        <p style={{ textAlign: 'justify' }}>
          NCCCO has established specific conditions and guidelines that each Practical Examination Test Site must adhere
          to. This Site Report is designed to ensure that these conditions are met. The Examiner is required to perform
          a site inspection prior to the start of the first examination and complete the Site Report form. The Examiner
          must arrive at the Test Site in sufficient time to verify by measuring with a tape the accuracy of the course
          layout with respect to the NCCCO Test Site Layout (CAD). The Examiner must also conduct a visual inspection of
          the crane for proper setup prior to testing any applicant. This Site Report must be presented on demand to any
          Practical Test Auditor.
        </p>
        <form style={{ marginTop: '16px' }}>
          <Field name="testSite" label="Test Site" component={TextField} />
          <Field name="testSiteNumber" label="Test Site #:" component={TextField} />
          <Field name="testSiteCoordinator" label="Name of Test Site Coordinator" component={TextField} />
          <Field name="date" label="Date" component={TextField} />
          <Field
            name="craneType"
            label="Crane Type"
            options={craneTypeOptions}
            optionsSequence={craneTypeOptionsSequence}
            component={RadioGroupField}
          />
          <hr />
          <div>Check the following items for compliance:</div>
          <Heading>PRE-TEST CANDIDATE BRIEFING AREA</Heading>
          <div>An indoor facility suitable for the Pre-Test Briefing of exam candidates, including:</div>
          <SiteReportCheckBox
            name="preTest01"
            testSessionId={this.props.testSessionId}
            label="Sufficient tables and chairs to seat all candidates for the Pre-Test Briefing"
          />
          <SiteReportCheckBox
            name="preTest02"
            testSessionId={this.props.testSessionId}
            label="A DVD player and television or computer for candidates to watch the CCO Practical Exam video"
          />
          <SiteReportCheckBox
            name="preTest03"
            testSessionId={this.props.testSessionId}
            label=" A location so that waiting candidates are unable to observe other candidates being tested, at least 50 ft. from any crane’s farthest testing radius"
          />
          <div>Candidate materials available:</div>
          <SiteReportCheckBox
            name="preTest04"
            testSessionId={this.props.testSessionId}
            label="A written description of the examination (NCCCO Mobile Crane Operator Candidate Handbook)"
          />
          <SiteReportCheckBox
            name="preTest05"
            testSessionId={this.props.testSessionId}
            label="A plan view of the Test Site Layout (CAD)"
          />
          <SiteReportCheckBox
            name="preTest06"
            testSessionId={this.props.testSessionId}
            label="Operator’s manual(s) and load chart(s) for all cranes that will be used for testing"
          />
          <SiteReportCheckBox
            name="preTest07"
            testSessionId={this.props.testSessionId}
            label="Instructions for the LMI system, if the crane is so equipped"
          />
          <div>This section is to be completed for each crane used during the testing session:</div>
          <Field name="craneModel" label="MAKE / MODEL OF CRANE:" component={TextField} />
          <Field name="craneSerialNumber" label="SERIAL NUMBER OF CRANE:" component={TextField} />
          <Heading>TEST SITE SETUP</Heading>
          <SiteReportCheckBox
            name="testSiteSetup01"
            testSessionId={this.props.testSessionId}
            label="Entire course is level within five percent of true level"
          />
          <SiteReportCheckBox
            name="testSiteSetup02"
            testSessionId={this.props.testSessionId}
            label="Zigzag Corridor has no more than a 6 in. maximum change in elevation"
          />
          <SiteReportCheckBox
            name="testSiteSetup03"
            testSessionId={this.props.testSessionId}
            label="Zigzag Corridor set up on asphalt, concrete surface, or firm and compacted sand, dirt, or gravel (free of vegetation), with a sufficiently uniform surface to permit the poles to stand vertical and slide freely; grass surfaces are not acceptable"
          />
          <SiteReportCheckBox
            name="testSiteSetup04"
            testSessionId={this.props.testSessionId}
            label="Free of debris, stored materials, surface irregularities, or hazards that could interfere with test maneuvers"
          />
          <SiteReportCheckBox
            name="testSiteSetup05"
            testSessionId={this.props.testSessionId}
            label="No obstructions are within 5 ft. of the test course in any direction"
          />
          <div>Using the Test Site Layout (CAD), verify the following measurements:</div>
          <SiteReportCheckBox
            name="testSiteSetup06"
            testSessionId={this.props.testSessionId}
            label="Distance from the center of rotation of the crane to the Starting Point"
          />
          <SiteReportCheckBox
            name="testSiteSetup07"
            testSessionId={this.props.testSessionId}
            label="Distance from the center of rotation of the crane to the center of Barrel #1 (± 1 in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup08"
            testSessionId={this.props.testSessionId}
            label="Distance from the center of rotation of the crane to the center of the Stop Circle (± 1 in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup09"
            testSessionId={this.props.testSessionId}
            label="Distance from the center of rotation of the crane to the center of the Test Weight Circle (± 2 in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup10"
            testSessionId={this.props.testSessionId}
            label="Distance from the center of rotation of the crane to the center of Barrel #2 (± 1 in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup11"
            testSessionId={this.props.testSessionId}
            label="Distance from the centerline of the crane to the second leg of the Zigzag Corridor (± 1 in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup12"
            testSessionId={this.props.testSessionId}
            label="Distance from the centerline of the crane to the first leg of the Zigzag Corridor (± 1 in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup13"
            testSessionId={this.props.testSessionId}
            label="Length of all six sides of the Zigzag Corridor (± ½ in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup14"
            testSessionId={this.props.testSessionId}
            label="Width of the Zigzag Corridor (± ½ in.)"
          />
          <SiteReportCheckBox
            name="testSiteSetup15"
            testSessionId={this.props.testSessionId}
            label="Distance between consecutive poles (2 ft. ± ½ in.)"
          />
          <Heading>BARRELS</Heading>
          <SiteReportCheckBox
            name="barrels01"
            testSessionId={this.props.testSessionId}
            label="Two empty steel drums, approximately 22 in. diameter and 34 in. tall (e.g., 55-gallon drums), open at one end"
          />
          <SiteReportCheckBox
            name="barrels02"
            testSessionId={this.props.testSessionId}
            label="Identified as No. 1 and No. 2 in numerals large enough to be clearly seen from the operator’s cab"
          />
          <SiteReportCheckBox
            name="barrels03"
            testSessionId={this.props.testSessionId}
            label="For Lattice Boom Cranes only, barrels are weighted with 20 lb. of ballast, evenly distributed in the base, so that the barrel is level"
          />
          <div style={{ paddingLeft: '30px' }}>
            <SiteReportCheckBox
              name="barrels03-a"
              testSessionId={this.props.testSessionId}
              label="The ballast does not prevent the overhaul ball from entering the barrel such that the horizontal line cannot drop below the rim."
            />
          </div>
          <SiteReportCheckBox
            name="barrels04"
            testSessionId={this.props.testSessionId}
            label="Each placed within a 2 in. wide circle line (with a maximum width of 2¼ in.) painted around the outline of the barrel"
          />
          <SiteReportCheckBox
            name="barrels05"
            testSessionId={this.props.testSessionId}
            label="Each placed on a 4 ft. × 4 ft. sheet of ¾-inch CDX-grade (or better) plywood."
          />
          <SiteReportCheckBox
            name="barrels06"
            testSessionId={this.props.testSessionId}
            label="Secured and weighted as necessary to prevent movement"
          />
          <SiteReportCheckBox
            name="barrels07"
            testSessionId={this.props.testSessionId}
            label="A spare barrel is available"
          />
          <Heading>POLES</Heading>
          <SiteReportCheckBox
            name="poles01"
            testSessionId={this.props.testSessionId}
            label="42 poles made of 1½-inch, white PVC pipe (SCH 40), each 3 ft. long; ball on each pole (one inside splice per 3 ft. pole permitted; outside splices of PVC pipes NOT permitted)"
          />
          <SiteReportCheckBox name="poles02" label="Top 12 in. painted or taped orange or red" />
          <SiteReportCheckBox
            name="poles03"
            testSessionId={this.props.testSessionId}
            label="Mounted to a platform made of two layers of ¾-inch, CDX-grade (or better) plywood or high density polyethylene (HDPE), cut 12 in. square"
          />
          <SiteReportCheckBox
            name="poles04"
            testSessionId={this.props.testSessionId}
            label="A taut, longitudinal string line placed on the ground under the centerline of each pole base. A cut concrete line may be used in lieu of a string line; no other materials are acceptable."
          />
          <SiteReportCheckBox
            name="poles05"
            testSessionId={this.props.testSessionId}
            label="Spare poles and bases available"
          />
          <Heading>DESIGNATED AREAS</Heading>
          <SiteReportCheckBox
            name="designatedAreas01"
            testSessionId={this.props.testSessionId}
            label="Starting Point is in line with the centerline of the crane and due left of the Test Weight Circle"
          />
          <SiteReportCheckBox
            name="designatedAreas02"
            testSessionId={this.props.testSessionId}
            label="Stop Circle has a 6 ft. 4 in. outside diameter (within a permitted tolerance of ± ½ in.), with a clearly marked inside line at least 2 in. wide and located per the Test Site Layout (CAD)"
          />
          <SiteReportCheckBox
            name="designatedAreas03"
            testSessionId={this.props.testSessionId}
            label="Test Weight Circle has a 6 ft. 4 in. outside diameter (within a permitted tolerance of ±½ in.), with a clearly marked inside line at least 2 in. wide and located per the Test Site Layout (CAD)"
          />
          <SiteReportCheckBox
            name="designatedAreas04"
            testSessionId={this.props.testSessionId}
            label="If marking circles, designated areas, or other parts of a course on plywood or mats, the borders must be marked with materials with a flat, uniform surface and a lip variance not to exceed 3/4 in. Any materials used may not interfere with the free movement of the pole bases."
          />
          <Heading>CRANE SELECTION AND SETUP</Heading>
          <SiteReportCheckBox
            name="craneSelectionSetup01"
            testSessionId={this.props.testSessionId}
            label="Crane as identified in the Test Site Layout (CAD)"
          />
          <Heading>TEST CRANE</Heading>
          <SiteReportCheckBox
            name="testCrane01"
            testSessionId={this.props.testSessionId}
            label="This crane has a current annual inspection with supporting documentation."
          />
          <SiteReportCheckBox
            name="testCrane02"
            testSessionId={this.props.testSessionId}
            label="Crane is set up on fully extended outriggers/stabilizers unless otherwise noted on the CAD drawing."
          />
          <SiteReportCheckBox
            name="testCrane03"
            testSessionId={this.props.testSessionId}
            label="Set up and leveled, in the location specified, ready for operation, with engine running, in accordance with the manufacturer’s recommendations"
          />
          <SiteReportCheckBox
            name="testCrane04"
            testSessionId={this.props.testSessionId}
            label="Boom length is as stated on Test Site Layout (CAD)"
          />
          <SiteReportCheckBox
            name="testCrane05"
            testSessionId={this.props.testSessionId}
            label="If applicable, the telescopic boom is extended to the designated length and marked in a manner that is clearly visible by the Examiner from the ground to see if the boom is telescoped during the test; if the crane is so equipped, the telescope function of the boom may be disabled during testing in lieu of the boom length being marked."
          />
          <Heading>OVERHAUL BALL</Heading>
          <SiteReportCheckBox
            name="overhaulBall01"
            testSessionId={this.props.testSessionId}
            label="Spherical in shape, smooth, constructed out of steel or iron, 30–48 in. circumference (9½–15 in. diameter), with a hook attached to the bottom of the ball and a 2 in. wide horizontal line of contrasting color, painted or taped around its center. The overhaul ball must satisfy OSHA and/or ASME requirements."
          />
          <SiteReportCheckBox
            name="overhaulBall02"
            testSessionId={this.props.testSessionId}
            label="If a second overhaul ball is used (only permitted for Task 3), it is attached so that the distance between the bottom of the upper ball and the top of the lower ball does not exceed 2 ft. The method of attachment must permit easy removal of second ball; NCCCO recommends using a sling, not a shackle."
          />
          <SiteReportCheckBox
            name="overhaulBall03"
            testSessionId={this.props.testSessionId}
            label="A length of 3/8-inch or 5/16-inch chain that can be quickly and easily attached and detached and is:"
          />
          <div style={{ paddingLeft: '30px' }}>
            <SiteReportCheckBox
              name="overhaulBall03-a"
              testSessionId={this.props.testSessionId}
              label="Painted orange or red to enable candidate to see the chain"
            />
            <SiteReportCheckBox
              name="overhaulBall03-b"
              testSessionId={this.props.testSessionId}
              label="Attached to bottom center of overhaul ball (recommend using a minimum 6-inch diameter ring)"
            />
            <SiteReportCheckBox
              name="overhaulBall03-c"
              testSessionId={this.props.testSessionId}
              label="36 in. long, measured from bottom of hook (± one chain link)"
            />
          </div>
          <Heading>TEST WEIGHT</Heading>
          <SiteReportCheckBox name="testWeight01" label="Weight as indicated in Test Site Layout (CAD)" />
          <SiteReportCheckBox
            name="testWeight02"
            testSessionId={this.props.testSessionId}
            label="Verified by a weight ticket, crane’s load indicating device (LMI, RCI, RCL), or other type of certification documenting the actual load weight available to the Examiner"
          />
          <SiteReportCheckBox
            name="testWeight02"
            testSessionId={this.props.testSessionId}
            label="Cylindrical in shape with no protruding edges"
          />
          <SiteReportCheckBox
            name="testWeight03"
            testSessionId={this.props.testSessionId}
            label="The diameter of the Test Weight is between 2 ft. and 4 ft. (same diameter from top to bottom). NOTE: 55-gallon drums DO NOT meet NCCCO Test Weight requirements and MAY NOT be used."
          />
          <SiteReportCheckBox
            name="testWeight04"
            testSessionId={this.props.testSessionId}
            label="Height is no more than two times its diameter and in any case does not exceed 5 ft. in height"
          />
          <SiteReportCheckBox
            name="testWeight05"
            testSessionId={this.props.testSessionId}
            label="Picking ears are mounted inside the Test Weight, or if mounted on the outside of the Test Weight the bottom of ears are at least 3 ft. 6” above the bottom of the weight"
          />
          <SiteReportCheckBox
            name="testWeight06"
            testSessionId={this.props.testSessionId}
            label="Method of attachment: Test Weight rigging is 2–4 ft. in length (load-bearing point to load-bearing point); if using multiple sling legs, recommend 60 degree sling angles (minimum 30 degrees required)"
          />
          <SiteReportCheckBox
            name="testWeight07"
            testSessionId={this.props.testSessionId}
            label="A 36 in. length of 3/8-inch or 5/16-inch chain (± one chain link)"
          />
          <div style={{ fontStyle: 'italic' }}>
            NOTE: To measure the chain length, attach the Test Weight to the crane hook. Raise the Test Weight until the
            chain barely touches the ground and measure from the lowest point of Test Weight (including feet) to ground.
          </div>
          <div style={{ paddingLeft: '30px' }}>
            <SiteReportCheckBox
              name="testWeight07a"
              testSessionId={this.props.testSessionId}
              label="Chain is painted orange or red to enable the candidate to see the chain"
            />
            <SiteReportCheckBox
              name="testWeight07b"
              testSessionId={this.props.testSessionId}
              label="Chain extends from bottom center of the Test Weight"
            />
          </div>
          <SiteReportCheckBox
            name="testWeight08"
            testSessionId={this.props.testSessionId}
            label="If the Test Weight has feet attached, they do not extend more than 4 in. below the bottom of the Test Weight"
          />
          <Heading>REEVING</Heading>
          <SiteReportCheckBox
            name="reeving01"
            testSessionId={this.props.testSessionId}
            label="The test crane is reeved with a single part line over the main boom point (or jib, if used)"
          />
          <SiteReportCheckBox
            name="reeving02"
            testSessionId={this.props.testSessionId}
            label="If a hook block is present on the crane, the crane must be equipped with an auxiliary boom head and the line used for testing must be reeved over the auxiliary boom head; otherwise the hook block must be removed"
          />
          <Heading>JIBS</Heading>
          <SiteReportCheckBox
            name="jibs01"
            testSessionId={this.props.testSessionId}
            label="Boom has no erected jib or extensions or auxiliary load line/blocks (stowed jibs/extensions are permitted), unless otherwise indicated in the Test Site Layout (CAD)"
          />
          <Heading>BLOCKING</Heading>
          <SiteReportCheckBox
            name="blocking01"
            testSessionId={this.props.testSessionId}
            label="Matting or cribbing installed, as necessary, to provide a sound foundation for the crane"
          />
          <Heading>LOAD INDICATORS</Heading>
          <SiteReportCheckBox
            name="loadIndicators01"
            testSessionId={this.props.testSessionId}
            label="If the crane is equipped with a load indicator or load moment indicating (LMI) system, the system must be programmed for the proper load ratings, parts of line, and other settings prior to the beginning of any testing; a representative of the test host organization who is familiar with the operation of the crane—and specifically with any LMI system on the crane—must be available near the test area during the times testing is being conducted"
          />
          <Heading>TEST WEIGHT RIGGING</Heading>
          <SiteReportCheckBox
            name="testWeightRigging01"
            testSessionId={this.props.testSessionId}
            label="All load-supporting components must be assembled in accordance with proper rigging practice and working load limits for the hardware used. Any specially fabricated structural components that are part of the load-supporting system must be designed and fabricated in accordance with the requirements of current applicable industry standards."
          />
          <Heading>TEST COURSE SETUP</Heading>
          <SiteReportCheckBox
            name="testCourseSetup01"
            testSessionId={this.props.testSessionId}
            label="The Practical Examiner whose signature appears at the end of this Site Report attests that he/she has set up the course"
          />
          <div style={{ fontStyle: 'italic' }}>(Check only if the Practical Examiner has set up the test course.)</div>
          <SiteReportCheckBox
            name="testCourseSetup02"
            testSessionId={this.props.testSessionId}
            label="The Examiner must have the following items for conducting the Practical Exam:"
          />
          <div style={{ paddingLeft: '30px', display: 'flex' }}>
            <div style={{ marginRight: '16px' }}>
              <SiteReportCheckBox
                name="testCourseSetup02a"
                testSessionId={this.props.testSessionId}
                label="Two stopwatches"
              />
              <SiteReportCheckBox
                name="testCourseSetup02b"
                testSessionId={this.props.testSessionId}
                label="Clip board"
              />
              <SiteReportCheckBox
                name="testCourseSetup02c"
                testSessionId={this.props.testSessionId}
                label="Anemometer (wind meter)"
              />
              <SiteReportCheckBox
                name="testCourseSetup02d"
                testSessionId={this.props.testSessionId}
                label="Pen or pencil"
              />
              <SiteReportCheckBox
                name="testCourseSetup02e"
                testSessionId={this.props.testSessionId}
                label="Spirit level (2 ft. minimum)"
              />
              <SiteReportCheckBox
                name="testCourseSetup02f"
                testSessionId={this.props.testSessionId}
                label="Two 100 ft. measuring tapes"
              />
            </div>
            <div>
              <SiteReportCheckBox
                name="testCourseSetup02g"
                testSessionId={this.props.testSessionId}
                label="Test Site Layout (CAD)"
              />
              <SiteReportCheckBox name="testCourseSetup02h" testSessionId={this.props.testSessionId} label="Proctor" />
              <SiteReportCheckBox
                name="testCourseSetup02i"
                testSessionId={this.props.testSessionId}
                label="Verbatim instructions"
              />
              <SiteReportCheckBox
                name="testCourseSetup02j"
                testSessionId={this.props.testSessionId}
                label="Notification of test email (new Test Sites, if applicable)"
              />
              <SiteReportCheckBox
                name="testCourseSetup02k"
                testSessionId={this.props.testSessionId}
                label="Personal protective equipment (hard hats, work boots)"
              />
              <SiteReportCheckBox
                name="testCourseSetup02l"
                testSessionId={this.props.testSessionId}
                label="30 ft. measuring tape"
              />
            </div>
          </div>
          <div style={{ fontStyle: 'italic' }}>
            Deviations from the above-noted requirements are not allowed without written consent from the NCCCO Western
            Regional Office.
          </div>
        </form>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    testSite: '',
    testSiteNumber: '',
    testSiteCoordinator: '',
    date: '',
    craneType: '',
    preTest01: false,
    preTest02: false,
    preTest03: false,
    preTest04: false,
    preTest05: false,
    preTest06: false,
    preTest07: false,
    craneModel: '',
    craneSerialNumber: '',
    testSiteSetup01: false,
    testSiteSetup02: false,
    testSiteSetup03: false,
    testSiteSetup04: false,
    testSiteSetup05: false,
    testSiteSetup06: false,
    testSiteSetup07: false,
    testSiteSetup08: false,
    testSiteSetup09: false,
    testSiteSetup10: false,
    testSiteSetup11: false,
    testSiteSetup12: false,
    testSiteSetup13: false,
    testSiteSetup14: false,
    testSiteSetup15: false,
    barrels01: false,
    barrels02: false,
    barrels03: false,
    'barrels03-a': false,
    barrels04: false,
    barrels05: false,
    barrels06: false,
    barrels07: false,
    poles01: false,
    poles02: false,
    poles03: false,
    poles04: false,
    poles05: false,
    designatedAreas01: false,
    designatedAreas02: false,
    designatedAreas03: false,
    designatedAreas04: false,
    craneSelectionSetup01: false,
    testCrane01: false,
    testCrane02: false,
    testCrane03: false,
    testCrane04: false,
    testCrane05: false,
    overhaulBall01: false,
    overhaulBall02: false,
    overhaulBall03: false,
    'overhaulBall03-a': false,
    'overhaulBall03-b': false,
    'overhaulBall03-c': false,
    testWeight01: false,
    testWeight02: false,
    testWeight02: false,
    testWeight03: false,
    testWeight04: false,
    testWeight05: false,
    testWeight06: false,
    testWeight07: false,
    testWeight07a: false,
    testWeight07b: false,
    testWeight08: false,
    reeving01: false,
    reeving02: false,
    jibs01: false,
    blocking01: false,
    loadIndicators01: false,
    testWeightRigging01: false,
    testCourseSetup01: false,
    testCourseSetup02: false,
    testCourseSetup02a: false,
    testCourseSetup02b: false,
    testCourseSetup02c: false,
    testCourseSetup02d: false,
    testCourseSetup02e: false,
    testCourseSetup02f: false,
    testCourseSetup02g: false,
    testCourseSetup02h: false,
    testCourseSetup02i: false,
    testCourseSetup02j: false,
    testCourseSetup02k: false,
    testCourseSetup02l: false
  })
})(SiteReportRouter);
