import React from 'react';

const OuterSection = ({ children }) => (
  <div>
    <span>Schedule:</span>
    <ul style={{ marginTop: 0 }}>{children}</ul>
  </div>
);

const ScheduleSection = props => {
  const { candidate } = props;

  if (candidate.practicalTestSchedules.length > 0) {
    return (
      <OuterSection>
        {candidate.practicalTestSchedules.map(schedule => {
          const { id, day, time } = schedule;
          const practiceHours = schedule.practice_hours && parseFloat(schedule.practice_hours);
          const practiceStr = practiceHours ? ` (+${practiceHours}hr. Practice)` : '';

          return <li key={id}>{`Day ${day} ${time}${practiceStr}`}</li>;
        })}
      </OuterSection>
    );
  }

  if (candidate.practiceTimeCredits) {
    const practiceHours = parseFloat(candidate.practiceTimeCredits);
    const practiceStr = `${practiceHours}hr. Practice`;
    return (
      <OuterSection>
        <li>{practiceStr}</li>
      </OuterSection>
    );
  }

  return (
    <OuterSection>
      <li>No scheduled practice/test</li>
    </OuterSection>
  );
};

export default ScheduleSection;
