import { create as axiosCreate } from 'axios';

const axios = axiosCreate({
  baseURL: process.env.REACT_APP_CRANEADMIN_URL
});

export const apiLogIn = (username, password, otp) => {
  return axios.post('/api/user/login', { username, password, otp });
};

export const apiGetApplicationTypes = () => {
  return axios.get('/api/application-type/active');
};

export const apiGetUserClasses = id => {
  return axios.get(`/api/user/classes?id=${id}`);
};

export const apiGetClassList = days => {
  return axios.get(`/api/test-session/current?daysAhead=${days}`);
};

export const apiGetPreviousClassList = days => {
  return axios.get(`/api/test-session/previous?daysPrevious=${days}`);
};

export const apiGetClassInfo = id => {
  return axios.get(`/api/test-session/roster-info?id=${id}`);
};

export const apiPostPendingTransaction = (userId, candidateId, details) => {
  return axios.post(`/api/pending-transaction/new`, {
    posted_by: userId,
    candidate_id: candidateId,
    ...details
  });
};

export const apiConfirmStartTraining = (candidateId, testSessionId, type) => {
  return axios.post(`/api/candidates/start-training?id=${candidateId}&testSessionId=${testSessionId}&type=${type}`);
};

export const apiConfirmStopTraining = (userId, trainingSessionId, photoBase64, contentType, fileExtension, grade) => {
  return axios.post(`/api/candidates/stop-training?id=${trainingSessionId}&new=0`, {
    userId,
    attestationPhoto: photoBase64,
    contentType,
    fileExtension,
    grade
  });
};

export const apiConfirmStopTrainingNew = (
  userId,
  candidateId,
  testSessionId,
  type,
  grade,
  photoBase64,
  contentType,
  fileExtension
) => {
  const payload = {
    userId,
    candidateId,
    testSessionId,
    attestationPhoto: photoBase64,
    contentType,
    fileExtension
  };

  if (type) {
    payload.type = type;

    if (type === '5_MIN' && grade && typeof grade === 'number') {
      payload.grade = grade;
    }
  }

  return axios.post(`/api/candidates/stop-training?new=1`, payload);
};

export const apiDeleteTrainingSession = trainingSessionId => {
  return axios.post(`/api/candidates/delete-training-session?id=${trainingSessionId}`);
};

export const apiConfirmDeclineTest = (candidateId, testSessionId, crane, photoBase64, contentType, fileExtension) => {
  return axios.post(`/api/candidates/decline-test?id=${candidateId}&crane=${crane}`, {
    testSessionId,
    attestationPhoto: photoBase64,
    contentType,
    fileExtension
  });
};

export const apiUploadPhotoBase64 = (candidateId, photoBase64, contentType, fileExtension) => {
  return axios.post(`/api/candidates/upload-photo?id=${candidateId}`, {
    photo: photoBase64,
    contentType,
    fileExtension
  });
};

export const apiUploadTrainingPhotoBase64 = (userId, trainingSessionId, photoBase64, contentType, fileExtension) => {
  return axios.post(`/api/candidates/upload-training-photo?trainingSessionId=${trainingSessionId}`, {
    userId,
    photo: photoBase64,
    contentType,
    fileExtension
  });
};

export const apiUploadTrainingPhotoBase64New = (
  userId,
  candidateId,
  testSessionId,
  photoBase64,
  contentType,
  fileExtension
) => {
  return axios.post(`/api/candidates/upload-training-photo`, {
    userId,
    candidateId,
    testSessionId,
    photo: photoBase64,
    contentType,
    fileExtension
  });
};

export const apiUploadScoreSheetBase64 = (
  candidateId,
  testSessionId,
  photoBase64,
  contentType,
  fileExtension,
  pageNum,
  pageType
) => {
  return axios.post(`/api/candidates/upload-score-sheet?id=${candidateId}&testSessionId=${testSessionId}`, {
    photo: photoBase64,
    contentType,
    fileExtension,
    pageNum,
    pageType
  });
};

export const apiDeleteScoreSheet = id => {
  return axios.post(`/api/candidates/delete-score-sheet?id=${id}`);
};

export const apiUploadTestSessionPhotoBase64 = (testSessionId, photoBase64, contentType, fileExtension) => {
  return axios.post(`/api/test-session/upload-photo?id=${testSessionId}`, {
    photo: photoBase64,
    contentType,
    fileExtension
  });
};

export const apiRegisterWalkInCandidate = (testSessionId, details) => {
  return axios.post(`/api/test-session/register-walk-in-candidate?id=${testSessionId}`, details);
};
