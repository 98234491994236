import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { formatMoney } from 'accounting';
import { postPendingTransaction } from '../../actionCreators';
import RadioGroupField from '../../components/form/RadioGroupField';

const options = {
  1: '1 hour',
  2: '2 hours',
  3: '3 hours'
};

const optionsSequence = [1, 2, 3];

const AddPracticeTimeRouter = props => (
  <div>
    <h2>Add Practice Time</h2>
    <div>
      {props.values.hours && parseFloat(props.values.hours) > 0 && (
        <div>Additional Practice Time Charge: {formatMoney(parseFloat(props.values.hours) * 125)}</div>
      )}
      <form>
        <Field
          name="hours"
          label="Practice Time Hour(s)"
          options={options}
          optionsSequence={optionsSequence}
          component={RadioGroupField}
        />
      </form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button className={`button is-success ${props.isSubmitting ? 'is-loading' : ''}`} onClick={props.handleSubmit}>
          Add Practice Time Charge
        </button>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postPendingTransaction
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(
  withFormik({
    handleSubmit: (values, { props }) => {
      const amount = parseFloat(values.hours) * 125;

      props
        .postPendingTransaction(props.candidate.id, 73, amount)
        .then(() => {
          props.history.goBack();
        })
        .catch(e => {
          console.error(e);
        });
    },
    mapPropsToValues: () => ({
      hours: ''
    }),
    validationSchema: Yup.object().shape({
      hours: Yup.number()
        .moreThan(0, 'Number of hours must be greater than 0.')
        .required('Number of hours is required.')
    })
  })(AddPracticeTimeRouter)
);
