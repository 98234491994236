import React from 'react';

const ElapsedTimeSection = props => {
  const minutes = Math.floor(props.elapsedTime / 60);
  const seconds = props.elapsedTime % 60;
  const secondsStr = seconds > 9 ? seconds : `0${seconds}`;
  const elapsedTime = `${minutes}:${secondsStr}`;
  const pastTimeLimit = props.elapsedTime > props.timeLimitSeconds;

  return (
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>Currently conducting: {props.label}</div>
      <div
        style={{
          fontSize: '1.5rem',
          marginBottom: '48px',
          border: '1px solid transparent',
          borderRadius: '4px',
          padding: '16px',
          color: '#fff',
          backgroundColor: pastTimeLimit ? '#ff3860' : '#209cee',
          minWidth: '266px',
          textAlign: 'center'
        }}
      >
        Elapsed Time: {elapsedTime}
      </div>
    </section>
  );
};

export default ElapsedTimeSection;
