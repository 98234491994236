import React from 'react';

const CameraPreview = props => (
  <div style={{ marginBottom: '20px', display: props.mode === 'CAMERA' ? 'block' : 'none' }}>
    <video
      ref={props.cameraPreviewRef}
      autoPlay
      onLoadedMetadata={e => {
        const { videoWidth, videoHeight } = e.currentTarget;
        props.setVideoDimensions(videoWidth, videoHeight);
      }}
    />
  </div>
);

export default CameraPreview;
