import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatMoney } from 'accounting';
import { S3_BUCKET_URL } from '../../lib/constants';
import ActionsButtons from '../ActionsButton';
import { getDeclinedTest } from '../../lib/candidate';
import { summarizeTransactions, summarizePendingTransactions } from '../../lib/candidateTransactions';
import { summarizeTrainingSessions } from '../../lib/trainingSessions';
import ScheduleSection from './ScheduleSection';

const getHeaderStyle = grade => {
  const gradeMapping = {
    1: {
      backgroundColor: '#ff3860',
      color: '#fff'
    },
    2: {
      backgroundColor: '#ffdd57',
      color: 'rgba(0, 0, 0, 0.7)'
    },
    3: {
      backgroundColor: '#23d160',
      color: '#fff'
    }
  };

  if (grade === null) {
    return {};
  }

  if (Object.keys(gradeMapping).includes(grade.toString())) {
    return gradeMapping[grade];
  }

  return {};
};

const checkIfPhotoRequired = (applicationTypeName, photoS3Key) => {
  if (photoS3Key || !applicationTypeName) {
    return false;
  }

  if (applicationTypeName === 'Test' || applicationTypeName === 'Retest') {
    return true;
  }

  return false;
};

class StudentCard extends Component {
  handleClickPhoto = () => {
    this.props.history.push(`${this.props.match.url}/candidate/${this.props.candidate.id}/photo`);
  };

  render() {
    const { candidate, testSessionId } = this.props;
    const {
      applicationFormSetup: {
        coreExamEnabled,
        writtenSWEnabled,
        writtenFXEnabled,
        practicalSWEnabled,
        practicalFXEnabled
      }
    } = candidate;
    const tSummary = summarizeTransactions(candidate.transactions, false);
    const pSummary = summarizePendingTransactions(candidate.pendingTransactions);

    const trainingSessions = candidate.trainingSessions.filter(
      trainingSession =>
        parseInt(testSessionId, 10) === trainingSession.test_session_id ||
        this.props.testSessionCounterpartId === trainingSession.test_session_id
    );

    const summary = summarizeTrainingSessions(trainingSessions);

    const { grade, assessmentComplete, familiarizationOngoing, familiarizationComplete } = summary;

    const freeTrainingComplete = assessmentComplete && familiarizationComplete;
    const headerStyle = getHeaderStyle(grade);

    const declinedFX = getDeclinedTest(candidate.declinedTests, 'fx');
    const declinedSW = getDeclinedTest(candidate.declinedTests, 'sw');

    const isPhotoRequired = checkIfPhotoRequired(candidate.applicationTypeName, candidate.photoS3Key);

    return (
      <div className="card" key={candidate.id} style={{ margin: '20px 0px' }}>
        <header className="card-header" style={headerStyle}>
          <div className="card-header-title" style={headerStyle}>
            {candidate.name}
            <div style={{ marginLeft: '20px' }}>
              {isPhotoRequired && (
                <span className="tag is-danger" style={{ marginLeft: '4px' }}>
                  Photo Required
                </span>
              )}
              {assessmentComplete && (
                <span className="tag is-success" style={{ marginLeft: '4px', border: '1px solid white' }}>
                  Pre-Assessment
                </span>
              )}
              {familiarizationOngoing && (
                <span className="tag is-danger" style={{ marginLeft: '4px', border: '1px solid white' }}>
                  20-min. Ongoing
                </span>
              )}
              {familiarizationComplete && (
                <span className="tag is-success" style={{ marginLeft: '4px', border: '1px solid white' }}>
                  20-min. Done
                </span>
              )}
              {candidate.scoreSheetPhotos.length > 0 && (
                <span className="tag is-info" style={{ marginLeft: '4px' }}>
                  Score Sheet&nbsp;
                  <i className="fa fa-check" aria-hidden="true" />
                </span>
              )}
              {declinedFX && (
                <span className="tag is-danger" style={{ marginLeft: '4px' }}>
                  Declined FX
                </span>
              )}
              {declinedSW && (
                <span className="tag is-danger" style={{ marginLeft: '4px' }}>
                  Declined SW
                </span>
              )}
            </div>
          </div>
        </header>
        <div className="card-content" style={{ padding: '8px' }}>
          <div className="content candidate-card-content">
            <div className="candidate-card-photo">
              <figure
                className="is-clickable"
                style={{
                  margin: '10px',
                  maxWidth: '100%'
                }}
                onClick={e => {
                  e.preventDefault();
                  this.handleClickPhoto();
                }}
              >
                <img
                  src={
                    candidate.photoS3Key
                      ? `${S3_BUCKET_URL}${candidate.photoS3Key}`
                      : `${process.env.REACT_APP_CRANEADMIN_URL}/images/preview-default.png`
                  }
                  alt={candidate.name}
                />
              </figure>
              <button
                type="button"
                className="button is-info"
                onClick={this.handleClickPhoto}
                style={{ backgroundColor: 'rgb(50, 115, 220)', padding: '2px 4px', fontSize: '12px' }}
              >
                Edit
              </button>
            </div>
            <div style={{ flexGrow: 2, display: 'flex', flexDirection: 'column' }}>
              <span>Remaining Amount: {formatMoney(tSummary.amountDue)}</span>
              {pSummary.totalPendingPayments > 0 && (
                <Fragment>
                  <span style={{ color: '#5cb85c' }}>Paid in Class: {formatMoney(pSummary.totalPendingPayments)}</span>{' '}
                </Fragment>
              )}
              {pSummary.totalPendingCharges > 0 && (
                <Fragment>
                  <span style={{ color: '#d9534f' }}>
                    Charged in Class: {formatMoney(pSummary.totalPendingCharges)}
                  </span>{' '}
                </Fragment>
              )}
              {this.props.ui.showExams && (
                <Fragment>
                  <div>
                    <div>Registered For:</div>
                    <ul style={{ marginTop: 0 }}>
                      {coreExamEnabled && <li>Written Core Exam</li>}
                      {writtenSWEnabled && <li>Written SW Exam</li>}
                      {writtenFXEnabled && <li>Written FX Exam</li>}
                      {practicalSWEnabled && <li>Practical SW Exam</li>}
                      {practicalFXEnabled && <li>Practical FX Exam</li>}
                    </ul>
                  </div>
                </Fragment>
              )}
              {this.props.ui.showSchedule && <ScheduleSection candidate={candidate} />}
              <ActionsButtons
                candidateId={candidate.id}
                testSessionId={testSessionId}
                freeTrainingComplete={freeTrainingComplete}
                assessmentComplete={assessmentComplete}
                familiarizationComplete={familiarizationComplete}
              />
            </div>
          </div>
        </div>
        <footer className="card-footer" style={{ textAlign: 'center' }}>
          <Link
            to={`${this.props.match.url}/candidate/${candidate.id}/receive-payment`}
            href={`${this.props.match.url}/candidate/${candidate.id}/receive-payment`}
            className="card-footer-item"
          >
            Payments
          </Link>
          <Link
            to={`${this.props.match.url}/candidate/${candidate.id}/add-practice-time`}
            href={`${this.props.match.url}/candidate/${candidate.id}/add-practice-time`}
            className="card-footer-item"
          >
            Add Practice Time
          </Link>
          <a href={`tel://${candidate.cellNumber}`} className="card-footer-item">
            Call
          </a>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = ({ ui }) => ({
  ui
});

export default connect(mapStateToProps)(StudentCard);
