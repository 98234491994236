import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { deriveNextPageAndType } from './helpers';
import ScoreSheetListSection from './ScoreSheetListSection';
import { parseScoreSheets } from '../../../lib/candidate';

const IndexPage = props => {
  const { scoreSheets, scoreSheetPages } = parseScoreSheets(props.candidate.scoreSheetPhotos);

  const { nextPage, nextPageType } = deriveNextPageAndType(scoreSheets, scoreSheetPages);

  return (
    <Fragment>
      <div>
        <h4 style={{ fontWeight: 'bold' }}>Score Sheet Photos</h4>
        <Link
          to={`${props.match.url}/capture/${nextPage}/${nextPageType}`}
          href={`${props.match.url}/capture`}
          className="button is-success"
        >
          Capture New Score Sheet Photo
        </Link>
      </div>
      <ScoreSheetListSection
        scoreSheetPhotos={props.candidate.scoreSheetPhotos}
        scoreSheets={scoreSheets}
        scoreSheetPages={scoreSheetPages}
        handleDeleteButtonClick={props.handleDeleteButtonClick}
      />
    </Fragment>
  );
};

export default IndexPage;
