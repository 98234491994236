import {
  apiLogIn,
  apiGetUserClasses,
  apiGetClassList,
  apiGetPreviousClassList,
  apiPostPendingTransaction,
  apiUploadPhotoBase64,
  apiUploadTrainingPhotoBase64,
  apiUploadTrainingPhotoBase64New,
  apiUploadScoreSheetBase64,
  apiDeleteScoreSheet,
  apiUploadTestSessionPhotoBase64,
  apiConfirmStartTraining,
  apiConfirmStopTraining,
  apiConfirmStopTrainingNew,
  apiDeleteTrainingSession,
  apiConfirmDeclineTest,
  apiGetApplicationTypes,
  apiRegisterWalkInCandidate
} from '../api';

import { apiGqlSignIn, apiGqlSignOut, apiGqlUserLog } from '../api/gql';

export const logIn = (username, password = null, otpToken = null) => async dispatch => {
  try {
    const { data: restLoginData } = await apiLogIn(username, password, otpToken);
    const { data: gqlLoginData } = await apiGqlSignIn(username, password);

    if (gqlLoginData) {
      localStorage.setItem('token', gqlLoginData.data.signIn);
    }

    dispatch({
      type: 'LOG_IN',
      payload: { user: restLoginData }
    });
  } catch (e) {
    console.error(e);
  }
};

export const logOut = () => async dispatch => {
  try {
    await apiGqlSignOut();
    localStorage.removeItem('token');
  } catch (e) {
    console.error(e);
  }

  dispatch({
    type: 'LOG_OUT'
  });
};

export const refreshClassList = () => async (dispatch, getState) => {
  try {
    const {
      user: { id: userId },
      ui: { allClassDayFilter, previousClassDayFilter }
    } = getState();

    const [{ data: userClasses }, { data: otherClasses }, { data: previousClasses }] = await Promise.all([
      apiGetUserClasses(userId),
      apiGetClassList(allClassDayFilter),
      apiGetPreviousClassList(previousClassDayFilter)
    ]);

    dispatch({
      type: 'UPDATE_USER',
      payload: userClasses
    });

    dispatch({
      type: 'UPDATE_OTHER_CLASS_LIST',
      payload: otherClasses
    });

    dispatch({
      type: 'UPDATE_PREVIOUS_CLASS_LIST',
      payload: previousClasses
    });
  } catch (e) {
    console.error(e);
  }
};

export const getApplicationTypes = () => dispatch => {
  apiGetApplicationTypes()
    .then(({ data }) => {
      dispatch({
        type: 'UPDATE_APPLICATION_TYPES',
        payload: data
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const updateClass = classInfo => ({
  type: 'UPDATE_CLASS',
  payload: {
    class: {
      id: classInfo.class.id,
      counterpartId: classInfo.class.counterpartId,
      testSessionPhotos: classInfo.class.testSessionPhotos,
      candidates: classInfo.candidates
    }
  }
});

export const changeClassListDayFilter = (listType, days) => dispatch => {
  apiGetClassList(days)
    .then(({ data }) => {
      dispatch({
        type: 'UPDATE_OTHER_CLASS_LIST',
        payload: data
      });
      dispatch({
        type: 'CHANGE_ALL_CLASS_DAY_FILTER',
        payload: days
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const changePreviousClassListDayFilter = days => dispatch => {
  apiGetPreviousClassList(days)
    .then(({ data }) => {
      dispatch({
        type: 'UPDATE_PREVIOUS_CLASS_LIST',
        payload: data
      });
      dispatch({
        type: 'CHANGE_PREVIOUS_CLASS_DAY_FILTER',
        payload: days
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const changeRosterSort = type => ({
  type: 'CHANGE_ROSTER_SORT',
  payload: type
});

export const toggleShowExams = () => ({
  type: 'TOGGLE_SHOW_EXAMS'
});

export const toggleShowSchedule = () => ({
  type: 'TOGGLE_SHOW_SCHEDULE'
});

export const changeCandidateFilter = filter => ({
  type: 'CHANGE_CANDIDATE_FILTER',
  payload: filter
});

export const updateUISettings = settings => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: 'UPDATE_UI_SETTINGS',
      payload: settings
    });
    resolve();
  });
};

export const closeDialog = () => ({
  type: 'CLOSE_DIALOG'
});

export const postUserLog = async payload => {
  await apiGqlUserLog(payload);
};

export const postPendingTransaction = (candidateId, details) => async (dispatch, getState) => {
  const { user } = getState();

  try {
    const { data } = await apiPostPendingTransaction(user.id, candidateId, details);

    await apiGqlUserLog({
      type: 'POST_PENDING_TX',
      details: {
        candidateId
      }
    });

    dispatch({
      type: 'ADD_PENDING_TRANSACTION',
      payload: {
        id: candidateId,
        pendingTransaction: data
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const uploadPhotoBase64 = (candidateId, photoBase64, contentType) => dispatch => {
  return new Promise((resolve, reject) => {
    apiUploadPhotoBase64(candidateId, photoBase64, contentType, '.png')
      .then(({ data }) => {
        apiGqlUserLog({
          type: 'UPLOAD_CANDIDATE_PHOTO',
          details: {
            candidateId
          }
        });
        dispatch({
          type: 'UPDATE_CANDIDATE',
          payload: data
        });
        resolve();
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const uploadTrainingPhotoBase64 = (candidateId, testSessionId, photoBase64, contentType) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    const { user, candidates } = getState();
    const candidate = candidates[candidateId];

    let trainingSessionId = undefined;

    try {
      trainingSessionId = candidate.trainingSessions.filter(
        trainingSession => parseInt(testSessionId, 10) === trainingSession.test_session_id && !trainingSession.end_time
      )[0].id;
    } catch (e) {}

    if (trainingSessionId) {
      apiUploadTrainingPhotoBase64(user.id, trainingSessionId, photoBase64, contentType, '.png')
        .then(({ data }) => {
          apiGqlUserLog({
            type: 'UPLOAD_TRAINING_PHOTO',
            details: {
              candidateId,
              testSessionId
            }
          });
          dispatch({
            type: 'UPDATE_TRAINING_SESSION',
            payload: {
              id: candidateId,
              trainingSession: data
            }
          });
          resolve();
        })
        .catch(e => {
          reject(e);
        });
    } else {
      apiUploadTrainingPhotoBase64New(user.id, candidate.id, testSessionId, photoBase64, contentType, '.png')
        .then(({ data }) => {
          apiGqlUserLog({
            type: 'UPLOAD_TRAINING_PHOTO',
            details: {
              candidateId,
              testSessionId
            }
          });
          dispatch({
            type: 'UPDATE_TRAINING_SESSION',
            payload: {
              id: candidateId,
              trainingSession: data
            }
          });
          resolve();
        })
        .catch(e => {
          reject(e);
        });
    }
  });
};

export const uploadScoreSheetBase64 = (
  candidateId,
  testSessionId,
  photoBase64,
  contentType,
  pageNum,
  pageType
) => dispatch => {
  return new Promise((resolve, reject) => {
    apiUploadScoreSheetBase64(candidateId, testSessionId, photoBase64, contentType, '.png', pageNum, pageType)
      .then(({ data }) => {
        apiGqlUserLog({
          type: 'UPLOAD_SCORE_SHEET',
          details: {
            candidateId,
            testSessionId
          }
        });
        dispatch({
          type: 'UPDATE_CANDIDATE_SCORE_SHEET',
          payload: {
            id: candidateId,
            scoreSheetPhoto: data.scoreSheetPhoto
          }
        });
        resolve();
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const deleteScoreSheet = (scoreSheetId, candidateId) => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiDeleteScoreSheet(scoreSheetId);
      dispatch({
        type: 'DELETE_CANDIDATE_SCORE_SHEET',
        payload: {
          id: candidateId,
          scoreSheetId
        }
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const uploadTestSessionPhotoBase64 = (testSessionId, photoBase64, contentType) => dispatch => {
  return new Promise((resolve, reject) => {
    apiUploadTestSessionPhotoBase64(testSessionId, photoBase64, contentType, '.png')
      .then(({ data }) => {
        apiGqlUserLog({
          type: 'UPLOAD_TEST_SESSION_PHOTO',
          details: {
            testSessionId
          }
        });
        dispatch({
          type: 'UPLOAD_TEST_SESSION_PHOTO',
          testSessionId,
          payload: data
        });
        resolve();
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const confirmStartTraining = (candidateId, testSessionId, type) => async dispatch => {
  const { data } = await apiConfirmStartTraining(candidateId, testSessionId, type);

  await apiGqlUserLog({
    type: 'CONFIRM_START_TRAINING',
    details: { candidateId, testSessionId, trainingType: type }
  });

  dispatch({
    type: 'ADD_TRAINING_SESSION',
    payload: {
      id: candidateId,
      trainingSession: data
    }
  });

  return;
};

export const confirmStopTraining = (
  candidateId,
  testSessionId,
  type,
  grade,
  photoBase64,
  givenTrainingSessionId = null
) => async (dispatch, getState) => {
  const { user, candidates } = getState();
  const candidate = candidates[candidateId];

  let trainingSessionId = givenTrainingSessionId;

  if (!trainingSessionId) {
    try {
      trainingSessionId = candidate.trainingSessions.filter(
        trainingSession => parseInt(testSessionId) === trainingSession.test_session_id && !trainingSession.end_time
      )[0].id;
    } catch (e) {}
  }

  if (trainingSessionId) {
    const { data } = await apiConfirmStopTraining(
      user.id,
      trainingSessionId,
      photoBase64,
      'image/svg+xml',
      '.svg',
      grade
    );
    await apiGqlUserLog({
      type: 'CONFIRM_STOP_TRAINING',
      details: { candidateId, testSessionId, trainingType: type, trainingGrade: grade }
    });

    dispatch({
      type: 'UPDATE_TRAINING_SESSION',
      payload: {
        id: candidateId,
        trainingSession: data
      }
    });
    return;
  } else {
    const { data } = await apiConfirmStopTrainingNew(
      user.id,
      candidate.id,
      testSessionId,
      type,
      grade,
      photoBase64,
      'image/svg+xml',
      '.svg'
    );
    await apiGqlUserLog({
      type: 'CONFIRM_STOP_TRAINING',
      details: { candidateId, testSessionId, trainingType: type, trainingGrade: grade }
    });

    dispatch({
      type: 'UPDATE_TRAINING_SESSION',
      payload: {
        id: candidateId,
        trainingSession: data
      }
    });
    return;
  }
};

export const deleteTrainingSession = (candidateId, trainingSessionId) => dispatch => {
  return new Promise((resolve, reject) => {
    apiDeleteTrainingSession(trainingSessionId)
      .then(() => {
        dispatch({
          type: 'DELETE_TRAINING_SESSION',
          payload: {
            id: candidateId,
            trainingSessionId
          }
        });
        resolve(true);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const confirmDeclineTest = (candidateId, testSessionId, crane, photoBase64) => async (dispatch, getState) => {
  const { data } = await apiConfirmDeclineTest(candidateId, testSessionId, crane, photoBase64, 'image/svg+xml', '.svg');
  await apiGqlUserLog({ type: 'CONFIRM_DECLINE_TEST', details: { candidateId, testSessionId, testType: crane } });

  dispatch({
    type: 'CONFIRM_DECLINE_TEST',
    payload: {
      id: candidateId,
      declinedTest: data
    }
  });

  return;
};

export const registerWalkInCandidate = (testSessionId, details) => async dispatch => {
  const { data } = await apiRegisterWalkInCandidate(testSessionId, details);
  dispatch({
    type: 'ADD_CANDIDATE',
    payload: {
      testSessionId,
      candidate: data
    }
  });
};
