import React, { Component, Fragment } from 'react';

const Line = ({ line }) => {
  const pathData = `M ${line.points.map(p => `${p.x} ${p.y}`).join(' L ')}`;

  const defaultStyle = {
    fill: 'none',
    strokeWidth: '1px',
    stroke: 'black',
    strokeLinejoin: 'round',
    strokeLinecap: 'round'
  };

  return <path style={{ ...defaultStyle, ...line.style }} d={pathData} />;
};

class CanvasField extends Component {
  state = {
    lines: [],
    lineRefs: {},
    isDrawing: false,
    nextId: 0,
    optionsWidth: '1px',
    optionsColor: 'black',
    width: window.innerWidth - 48
  };

  handleResize = () => {
    this.setState({
      width: window.innerWidth - 48
    });
  };

  reset = () => {
    this.setState({
      lines: [],
      lineRefs: {},
      isDrawing: false
    });
  };

  getCoordinates = e => {
    const boundingRect = this.drawArea.getBoundingClientRect();

    return {
      x: e.clientX - boundingRect.left,
      y: e.clientY - boundingRect.top
    };
  };

  addLine = newLine => {
    this.setState(prevState => ({
      lines: [...this.state.lines, [newLine.id]],
      lineRefs: {
        ...prevState.lineRefs,
        [newLine.id]: newLine
      },
      nextId: prevState.nextId + 1,
      isDrawing: true
    }));
  };

  addPointToLastLine = point => {
    this.setState(prevState => {
      const lastLineId = prevState.lines[prevState.lines.length - 1];
      const lastLine = prevState.lineRefs[lastLineId];
      lastLine.points.push(point);
      const newLines = [...prevState.lines.slice(0, prevState.lines.length - 1), lastLine];

      return {
        lineRefs: {
          ...prevState.lineRefs,
          [lastLineId]: lastLine
        }
      };
    });
  };

  handleMouseDown = e => {
    e.preventDefault();

    if (e.button !== 0) {
      return;
    }

    const newLine = {
      id: this.state.nextId,
      style: {
        strokeWidth: this.state.optionsWidth,
        stroke: this.state.optionsColor
      },
      points: [this.getCoordinates(e)]
    };

    this.addLine(newLine);
  };

  handleMouseMove = e => {
    e.preventDefault();
    if (!this.state.isDrawing) {
      return;
    }

    const point = this.getCoordinates(e);

    this.addPointToLastLine(point);
  };

  handleMouseUp = e => {
    e.preventDefault();
    this.setState({ isDrawing: false });
  };

  handleTouchStart = e => {
    const newLine = {
      id: this.state.nextId,
      style: {
        strokeWidth: this.state.optionsWidth,
        stroke: this.state.optionsColor
      },
      points: [this.getCoordinates(e.targetTouches[0])]
    };

    this.addLine(newLine);
  };

  handleTouchMove = e => {
    if (!this.state.isDrawing) {
      return;
    }

    const point = this.getCoordinates(e.targetTouches[0]);

    this.addPointToLastLine(point);
  };

  handleTouchEnd = () => {
    this.setState({ isDrawing: false });
  };

  handleChangeColor = e => {
    this.setState({
      optionsColor: e.currentTarget.value
    });
  };

  handleChangeWidth = e => {
    this.setState({
      optionsWidth: e.currentTarget.value
    });
  };

  handleClickConfirm = () => {
    const serializedSvg = new XMLSerializer().serializeToString(this.resultImage);
    const encodedData = `data:image/svg+xml;base64,${window.btoa(serializedSvg)}`;
    this.props.handleConfirm(encodedData);
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  render() {
    return (
      <div
        className={this.props.className}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...this.props.style }}
      >
        <div
          ref={drawArea => {
            this.drawArea = drawArea;
          }}
          style={{
            width: this.props.width || this.state.width,
            height: this.props.height || '400px',
            border: '1px solid black'
          }}
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          onMouseLeave={this.handleMouseUp}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchCancel={this.handleMouseUp}
          onTouchEnd={this.handleMouseUp}
        >
          <svg
            style={{ width: '100%', height: '100%' }}
            ref={resultImage => {
              this.resultImage = resultImage;
            }}
          >
            {this.state.lines.map(lineId => {
              const line = this.state.lineRefs[lineId];
              return <Line key={lineId} line={line} />;
            })}
          </svg>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
          <button type="button" className="button is-danger" onClick={this.reset} style={{ marginRight: '8px' }}>
            Reset
          </button>
          <div className="select" style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '4px' }}>Color</label>
            <select value={this.state.optionsColor} onChange={this.handleChangeColor}>
              <option value="black">Black</option>
              <option value="red">Red</option>
            </select>
          </div>
          <div className="select" style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '4px' }}>Width</label>
            <select value={this.state.optionsWidth} onChange={this.handleChangeWidth}>
              <option value="1px">1</option>
              <option value="2px">2</option>
              <option value="3px">3</option>
              <option value="4px">4</option>
            </select>
          </div>
        </div>
        {!this.props.isFinished && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px', marginLeft: 'auto' }}>
            <button
              type="button"
              className={`button is-success ${this.props.isLoading ? 'is-loading' : ''}`}
              onClick={this.handleClickConfirm}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default CanvasField;
