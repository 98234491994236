import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';

class ScoreSheetEntry extends Component {
  state = {
    isDeleteLoading: false
  };

  handleDeleteButtonClick = e => {
    try {
      const id = parseInt(e.currentTarget.dataset.id, 10);
      this.setState(
        {
          isDeleteLoading: true
        },
        async () => {
          await this.props.handleDeleteButtonClick(id);
          this.setState({ isDeleteLoading: false });
        }
      );
    } catch (e) {
      this.setState({ isDeleteLoading: false });
      console.error(e);
    }
  };

  render() {
    const { props } = this;

    return (
      <Fragment>
        <a className="button is-link" href={props.url} download style={{ marginRight: '20px', minWidth: '180px' }}>
          {props.text}
          &nbsp;
          <i className="fa fa-download" aria-hidden="true" />
        </a>
        <button
          type="button"
          className={`button is-danger ${this.state.isDeleteLoading ? 'is-loading' : ''}`}
          data-id={props.id}
          onClick={this.handleDeleteButtonClick}
          style={{ marginRight: '20px' }}
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
        <span style={{ fontStyle: 'italic' }}>
          {moment.tz(props.dateCreated, 'America/Los_Angeles').format('M-D-YYYY h:mm a z')}
        </span>
      </Fragment>
    );
  }
}

export default ScoreSheetEntry;
