import React, { Component } from 'react';
import PhotoCapture from '../../../components/common/PhotoCapture';

class PhotoCapturePage extends Component {
  constructor(props) {
    super(props);

    const { pageNum, pageType } = this.props.match.params;

    this.state = {
      pageNum: parseInt(pageNum || 1, 10),
      pageType: pageType || 'FRONT'
    };
  }

  handleCapturePhoto = photoBase64 => {
    return this.props.handleCapturedPhoto(photoBase64, this.state.pageNum, this.state.pageType);
  };

  handlePageNumChange = e => {
    this.setState({
      pageNum: parseInt(e.currentTarget.value, 10)
    });
  };

  handlePageTypeChange = e => {
    this.setState({
      pageType: e.currentTarget.dataset.value
    });
  };

  render() {
    const { props } = this;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PhotoCapture handleCapturedPhoto={this.handleCapturePhoto} imgDefaultSrc={null} imgDefaultAlt={null} />
        <div style={{ display: 'flex', marginTop: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
            <div>Page Number:&nbsp;</div>
            <input
              className="input"
              type="number"
              value={this.state.pageNum}
              style={{ maxWidth: '50px' }}
              onChange={this.handlePageNumChange}
            />
          </div>
          <div className="control" style={{ display: 'flex', alignItems: 'center' }}>
            <label className="radio">
              <input
                type="radio"
                onChange={this.handlePageTypeChange}
                data-value="FRONT"
                checked={this.state.pageType === 'FRONT'}
              />
              Front
            </label>
            <label className="radio">
              <input
                type="radio"
                onChange={this.handlePageTypeChange}
                data-value="BACK"
                checked={this.state.pageType === 'BACK'}
              />
              Back
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default PhotoCapturePage;
