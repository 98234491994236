import React, { Fragment } from 'react';
import ScoreSheetEntry from './ScoreSheetEntry';
import { S3_BUCKET_URL } from '../../../lib/constants';

function BaseContainer({ children }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <h4 style={{ fontWeight: 'bold' }}>Score Sheet Photos</h4>
        {children}
      </div>
    </div>
  );
}

function EmptyPlaceholder() {
  return <div>No score sheet photos have been uploaded for this candidate.</div>;
}

function ScoreSheetListPaginated({ scoreSheets, scoreSheetPages, handleDeleteButtonClick }) {
  return scoreSheetPages.map(page => {
    const scoreSheet = scoreSheets[page];
    const frontPages = scoreSheet['FRONT'];
    const backPages = scoreSheet['BACK'];

    return (
      <div key={page} style={{ marginBottom: '8px' }}>
        <h4>{`Page ${page}:`}</h4>
        {frontPages &&
          frontPages.length > 0 &&
          frontPages.map(page => (
            <div key={page.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <ScoreSheetEntry
                id={page.id}
                handleDeleteButtonClick={handleDeleteButtonClick}
                url={`${S3_BUCKET_URL}${page.s3_key}`}
                dateCreated={page.date_created}
                text="Download (Front)"
              />
            </div>
          ))}
        {backPages &&
          backPages.length > 0 &&
          backPages.map(page => (
            <div key={page.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <ScoreSheetEntry
                id={page.id}
                handleDeleteButtonClick={handleDeleteButtonClick}
                url={`${S3_BUCKET_URL}${page.s3_key}`}
                dateCreated={page.date_created}
                text="Download (Back)"
              />
            </div>
          ))}
      </div>
    );
  });
}

function ScoreSheetListUnsorted(scoreSheets, handleDeleteButtonClick) {
  if (scoreSheets && scoreSheets.length) {
    return (
      <div>
        <h4>Unsorted:</h4>
        {scoreSheets.map(scoreSheet => (
          <div key={scoreSheet.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <ScoreSheetEntry
              id={scoreSheet.id}
              handleDeleteButtonClick={handleDeleteButtonClick}
              url={`${S3_BUCKET_URL}${scoreSheet.s3_key}`}
              dateCreated={scoreSheet.date_created}
              text="Download"
            />
          </div>
        ))}
      </div>
    );
  }
  return null;
}

function ScoreSheetListSection({ scoreSheets, scoreSheetPages, scoreSheetPhotos, handleDeleteButtonClick }) {
  return (
    <BaseContainer>
      {scoreSheetPhotos.length === 0 ? (
        <EmptyPlaceholder />
      ) : (
        <Fragment>
          <ScoreSheetListPaginated
            scoreSheets={scoreSheets}
            scoreSheetPages={scoreSheetPages}
            handleDeleteButtonClick={handleDeleteButtonClick}
          />
          <ScoreSheetListUnsorted
            scoreSheets={scoreSheets.UNSORTED}
            handleDeleteButtonClick={handleDeleteButtonClick}
          />
        </Fragment>
      )}
    </BaseContainer>
  );
}

export default ScoreSheetListSection;
