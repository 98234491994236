import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { withRouter, Link } from 'react-router-dom';
import { convertSecondsToMinutesStr } from '../../../lib/time';

function deriveStateFromProps({ ongoingTraining }) {
  return ongoingTraining.map(session => {
    const startTimeM = moment.tz(session.startTime, 'America/Los_Angeles');
    const timeNowM = moment().tz('America/Los_Angeles');
    const elapsedTime = timeNowM.diff(startTimeM, 'seconds');

    return {
      ...session,
      elapsedTime
    };
  });
}

function OngoingTraining(props) {
  const intervalTimer = useRef(undefined);
  const [ongoingTraining, setOngoingTraining] = useState(deriveStateFromProps(props));

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      setOngoingTraining(deriveStateFromProps(props));
    }, 1000);

    return () => {
      clearInterval(intervalTimer.current);
    };
  }, []);

  return (
    <div className="card" style={{ width: '50%' }}>
      <header className="card-header">
        <div className="card-header-title">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span>Ongoing Training</span>
            <button
              type="button"
              onClick={props.toggleDetails}
              className={`button ${props.detailsVisible ? 'is-danger' : 'is-link'}`}
              style={{
                lineHeight: '1.5',
                height: 'auto',
                padding: '0 8px',
                border: 'none',
                width: '70px'
              }}
            >
              {props.detailsVisible ? 'Hide' : 'Expand'}
            </button>
          </div>
        </div>
      </header>
      {props.detailsVisible && (
        <div className="card-content">
          {ongoingTraining.map(training => {
            const isTimeUp = training.elapsedTime > 1200;

            return (
              <div key={training.id} style={{ marginBottom: '8px' }}>
                <Link
                  to={`${props.location.pathname}/candidate/${training.id}/stop-training`}
                  className={`button ${isTimeUp ? 'is-danger' : 'is-link'}`}
                >
                  {training.name}: {convertSecondsToMinutesStr(training.elapsedTime)}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default withRouter(OngoingTraining);
