import React, { Component } from 'react';
import ClassListTable from '../components/ClassListTable';

class SchedulePage extends Component {
  state = {
    currentAndUpcomingClassesVisible: false,
    previousClassesVisible: false
  };

  handleToggleCurrentAndUpcomingClasses = () => {
    this.setState({ currentAndUpcomingClassesVisible: !this.state.currentAndUpcomingClassesVisible });
  };

  handleTogglePreviousClasses = () => {
    this.setState({ previousClassesVisible: !this.state.previousClassesVisible });
  };

  componentDidMount() {
    this.props.refreshClassList();
  }

  render() {
    const { props } = this;

    let prevClassesSection = null;
    if (this.state.previousClassesVisible) {
      if (props.user.previousClasses.length > 0) {
        prevClassesSection = <ClassListTable classes={props.user.previousClasses} noStatus />;
      } else {
        prevClassesSection = 'No classes found.';
      }
    }

    let currentAndUpcomingClassesSection = null;
    if (this.state.currentAndUpcomingClassesVisible) {
      if (props.user.otherClasses.length > 0) {
        currentAndUpcomingClassesSection = <ClassListTable classes={props.user.otherClasses} noStatus />;
      } else {
        currentAndUpcomingClassesSection = 'No classes found.';
      }
    }

    return (
      <div className="container">
        <div style={{ marginBottom: '24px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px',
              backgroundColor: '#fafafa',
              borderRadius: '4px'
            }}
          >
            <h4 style={{ fontWeight: 'bold' }}>Assigned Classes</h4>
          </div>
          <div>
            {props.user.ongoingClasses.length > 0 && (
              <ClassListTable status="Ongoing" classes={props.user.ongoingClasses} />
            )}
            {props.user.upcomingClasses.length > 0 && (
              <ClassListTable status="Upcoming" classes={props.user.upcomingClasses} />
            )}
            {props.user.ongoingClasses.length === 0 && props.user.upcomingClasses.length === 0 && (
              <div style={{ marginLeft: '12px' }}>No ongoing or upcoming assigned classes.</div>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '24px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: '12px',
              paddingLeft: '12px',
              backgroundColor: '#fafafa',
              borderRadius: '4px'
            }}
          >
            <h4 style={{ fontWeight: 'bold', marginRight: '30px', marginBottom: '12px' }}>All Previous Classes</h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="field">
                <p className="control has-icons-left">
                  <span className="select">
                    <select
                      value={props.previousClassDayFilter}
                      onChange={e => {
                        props.changePreviousClassListDayFilter(parseInt(e.currentTarget.value));
                      }}
                    >
                      <option value={7}>Last 7 days</option>
                      <option value={30}>Last 30 days</option>
                      <option value={93}>Last 3 months</option>
                      <option value={365}>Last year</option>
                    </select>
                  </span>
                  <span className="icon is-small is-left">
                    <i className="fa fa-calendar" />
                  </span>
                </p>
              </div>
              <button
                type="button"
                onClick={this.handleTogglePreviousClasses}
                className="button is-link"
                style={{ marginBottom: '12px', marginRight: '12px', marginLeft: '24px', minWidth: '65px' }}
              >
                {this.state.previousClassesVisible ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          {prevClassesSection}
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: '12px',
              paddingLeft: '12px',
              backgroundColor: '#fafafa',
              borderRadius: '4px'
            }}
          >
            <h4 style={{ fontWeight: 'bold', marginRight: '30px', marginBottom: '12px' }}>
              All Current & Upcoming Classes
            </h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="field">
                <p className="control has-icons-left">
                  <span className="select">
                    <select
                      value={props.allClassDayFilter}
                      onChange={e => {
                        props.changeClassListDayFilter('otherClasses', parseInt(e.currentTarget.value));
                      }}
                    >
                      <option value={7}>Next 7 days</option>
                      <option value={30}>Next 30 days</option>
                      <option value={93}>Next 3 months</option>
                      <option value={365}>Next year</option>
                    </select>
                  </span>
                  <span className="icon is-small is-left">
                    <i className="fa fa-calendar" />
                  </span>
                </p>
              </div>
              <button
                type="button"
                onClick={this.handleToggleCurrentAndUpcomingClasses}
                className="button is-link"
                style={{ marginBottom: '12px', marginRight: '12px', marginLeft: '24px', minWidth: '65px' }}
              >
                {this.state.currentAndUpcomingClassesVisible ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          {currentAndUpcomingClassesSection}
        </div>
      </div>
    );
  }
}

export default SchedulePage;
