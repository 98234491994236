import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import ElapsedTimeSection from './ElapsedTimeSection';
import { S3_BUCKET_URL } from '../../../lib/constants';
import CanvasField from '../../../components/form/CanvasField';

class StopTrainingPage extends Component {
  state = {
    hasSigned: false,
    isLoading: false,
    isFinished: false,
    elapsedTime: ''
  };

  get currentTrainingSession() {
    const { trainingSessions } = this.props.candidate;
    const unfinishedSessions = trainingSessions.filter(session => {
      return session.type === '20_MIN' && !session.end_time;
    });

    if (unfinishedSessions.length > 0) {
      return unfinishedSessions[0];
    }

    return null;
  }

  updateElapsedTime() {
    if (!this.currentTrainingSession) {
      return;
    }

    const startTimeM = moment.tz(this.currentTrainingSession.start_time, 'America/Los_Angeles');
    const timeNowM = moment().tz('America/Los_Angeles');
    const elapsedTime = timeNowM.diff(startTimeM, 'seconds');

    this.setState({ elapsedTime });
  }

  logOngoingTraining() {
    if (!this.currentTrainingSession) {
      return;
    }

    this.props.postUserLog({
      type: 'TRAINING_CANDIDATE',
      details: {
        candidateId: this.props.candidate.id,
        testSessionId: this.props.testSessionId,
        trainingType: '20_MIN'
      }
    });
  }

  handleStartTrainingClick = async () => {
    try {
      await this.props.confirmStartTraining(this.props.candidate.id, this.props.testSessionId, '20_MIN');
    } catch (e) {
      console.error(e);
    }
  };

  handleConfirm = imageBase64 => {
    this.setState({ isLoading: true }, async () => {
      const { currentTrainingSession } = this;

      try {
        if (currentTrainingSession) {
          await this.props.confirmStopTraining(
            this.props.candidate.id,
            this.props.testSessionId,
            this.props.type,
            null,
            imageBase64,
            currentTrainingSession.id
          );
        } else {
          await this.props.confirmStopTraining(
            this.props.candidate.id,
            this.props.testSessionId,
            this.props.type,
            null,
            imageBase64
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.setState({ isLoading: false, isFinished: true });
      this.props.history.push(`/class/${this.props.testSessionId}`);
    });
  };

  handleClickBack = () => {
    this.props.history.push(`/class/${this.props.testSessionId}`);
  };

  componentDidMount() {
    this.intervalTimer = setInterval(() => {
      if (this.currentTrainingSession) {
        this.updateElapsedTime();
      }
    }, 1000);

    this.intervalLogger = setInterval(() => {
      this.logOngoingTraining();

      if (this.state.elapsedTime >= 900) {
        this.notificationSound.play();
      }
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
    clearInterval(this.intervalLogger);
  }

  render() {
    const { props, currentTrainingSession } = this;
    const currentLocalTime = moment.tz(new Date(), moment.tz.guess());
    const convertedTime = moment(currentLocalTime).tz('America/Los_Angeles');

    let trainingPhotos = [];

    if (props.candidate.trainingSessions.length > 0) {
      const trainingSession = props.candidate.trainingSessions.filter(
        trainingSession =>
          parseInt(props.testSessionId) === trainingSession.test_session_id && !trainingSession.end_time
      )[0];

      if (trainingSession && trainingSession.trainingPhotos.length > 0) {
        trainingPhotos = trainingSession.trainingPhotos;
      }
    }

    if (currentTrainingSession) {
      return (
        <section className="section--stop-training">
          <ElapsedTimeSection elapsedTime={this.state.elapsedTime} label="20-minute Training" timeLimitSeconds={1200} />
          <audio
            ref={notificationSound => {
              this.notificationSound = notificationSound;
            }}
          >
            <source src="./time-up.mp3" type="audio/mp3" />
          </audio>
          <div>
            <CanvasField
              className="canvas-field--stop-training"
              handleConfirm={this.handleConfirm}
              isLoading={this.state.isLoading}
              isFinished={this.state.isFinished}
            />
            {this.state.isFinished && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                <button type="buton" className="button is-success" onClick={this.handleClickBack}>
                  Go Back
                </button>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            {trainingPhotos.length > 0 && (
              <div style={{ width: '100%' }}>
                <h4 style={{ fontWeight: 'bold' }}>Training Photos</h4>
                {trainingPhotos.map(trainingPhoto => (
                  <div key={trainingPhoto.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={`${S3_BUCKET_URL}${trainingPhoto.s3_key}`}
                        alt="Training Session"
                        style={{ maxWidth: '100px', marginBottom: '8px' }}
                      />
                      <a className="button is-link" href={`${S3_BUCKET_URL}${trainingPhoto.s3_key}`} download>
                        Download&nbsp;
                        <i className="fa fa-download" aria-hidden="true" />
                      </a>
                    </div>
                    <span style={{ fontStyle: 'italic' }}>
                      {moment.tz(trainingPhoto.created_at, 'America/Los_Angeles').format('M-D-YYYY h:mm a z')}
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <div style={{ textAlign: 'right', marginRight: '10px' }}>
                <h4>Name:</h4>
                <div>Date:</div>
                <div>Time (Local):</div>
                <div>Time (Main Office):</div>
              </div>
              <div>
                <h4>{props.candidate.name}</h4>
                <div>{currentLocalTime.format('M/D/YYYY')}</div>
                <div>{currentLocalTime.format('h:mm a z')}</div>
                <div>{convertedTime.format('h:mm a z')}</div>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return (
      <section
        className="section--stop-training"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <button type="button" onClick={this.handleStartTrainingClick} className="button is-large is-info">
          Start 20-Min. Training
        </button>
      </section>
    );
  }
}

export default StopTrainingPage;
