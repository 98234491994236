import React, { Component, Fragment } from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import {
  logIn,
  logOut,
  refreshClassList,
  getApplicationTypes,
  changeClassListDayFilter,
  changePreviousClassListDayFilter,
  updateClass
} from './actionCreators';
import Nav from './components/Nav';
import LoginPage from './pages/LoginPage';
import SchedulePage from './pages/SchedulePage';
import ClassUIOptionsPage from './pages/ClassUIOptionsPage';
import AdminClassContainer from './pages/AdminClassContainer';
import 'animate.css/animate.min.css';
import 'bulma/css/bulma.css';
import 'font-awesome/css/font-awesome.css';
import './App.css';

class App extends Component {
  state = {
    isLoading: false,
    displayUpdatePrompt: false
  };

  showUpdatePrompt = () => {
    this.setState({ displayUpdatePrompt: true });
  };

  hideUpdatePrompt = () => {
    this.setState({ displayUpdatePrompt: false });
  };

  clearCacheAndReload = () => {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName);
      });
      window.location.reload();
    });
  };

  componentDidMount() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(() => undefined);
    }

    this.props.getApplicationTypes();
    const params = parse(this.props.location.search);

    if (params.otp && params.user) {
      this.setState({ isLoading: true }, () => {
        this.props
          .logIn(params.user, null, params.otp)
          .then(() => {
            this.props.history.push('/');
            this.setState({ isLoading: false });
          })
          .catch(e => {
            this.props.logOut();
            this.setState({ isLoading: false });
          });
      });
    }

    window.addEventListener('newContentAvailable', this.showUpdatePrompt);
  }

  componentWillUnmount() {
    window.removeEventListener('newConentAvailable', this.showUpdatePrompt);
  }

  render() {
    const isNotHome = this.props.location.pathname !== '/';
    const isNotSignedIn = !this.props.isLoggedIn;
    if (isNotHome && isNotSignedIn) {
      return <Redirect to="/" />;
    }

    return (
      <Fragment>
        <div>
          <Nav />
          <section className="section" style={{ marginTop: '8px' }}>
            {this.state.isLoading ? (
              <button className="button is-info is-loading" style={{ width: '200px' }} />
            ) : (
              <Fragment>
                <Route
                  path="/"
                  exact
                  render={() =>
                    this.props.isLoggedIn ? (
                      <SchedulePage
                        user={this.props.user}
                        refreshClassList={this.props.refreshClassList}
                        allClassDayFilter={this.props.ui.allClassDayFilter}
                        changeClassListDayFilter={this.props.changeClassListDayFilter}
                        previousClassDayFilter={this.props.ui.previousClassDayFilter}
                        changePreviousClassListDayFilter={this.props.changePreviousClassListDayFilter}
                      />
                    ) : (
                      <LoginPage logIn={this.props.logIn} />
                    )
                  }
                />
                <Route
                  path="/class/:id"
                  render={({ match }) => (
                    <AdminClassContainer
                      match={match}
                      userId={this.props.user.id}
                      id={match.params.id}
                      classInfo={this.props.classes[match.params.id]}
                      candidates={this.props.candidates}
                      updateClass={this.props.updateClass}
                      ongoingTraining={this.props.ongoingTraining}
                    />
                  )}
                />
                <Route path="/options" render={({ history: { goBack } }) => <ClassUIOptionsPage goBack={goBack} />} />
              </Fragment>
            )}
          </section>
          {this.state.displayUpdatePrompt && (
            <div
              className="notification is-success animated fadeIn"
              style={{ position: 'sticky', bottom: '24px', right: '24px', float: 'right', maxWidth: '40vw' }}
            >
              <button className="delete" onClick={this.hideUpdatePrompt} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>New update installed. Please reload app to see updated content.</div>
                <button type="button" className="button" onClick={this.clearCacheAndReload}>
                  Reload
                </button>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logIn,
      logOut,
      refreshClassList,
      getApplicationTypes,
      updateClass,
      changeClassListDayFilter,
      changePreviousClassListDayFilter
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
