import 'webrtc-adapter';
import _throttle from 'lodash/throttle';
import React from 'react';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { loadState, saveState } from './localStorage';
import { defaultState, RootReducer } from './reducers';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import upgradeSchema from './schemaUpgrades';

const schemaVersion = '1.6';

async function prepareSWAndLocalState() {
  try {
    const localSchemaVersion = localStorage.getItem('schemaVersion');
    let persistedState = {};

    if (schemaVersion !== localSchemaVersion) {
      persistedState = await upgradeSchema(localSchemaVersion, schemaVersion);
    } else {
      persistedState = loadState();
    }
    return persistedState;
  } catch (err) {
    console.error(err);
  }
}

prepareSWAndLocalState().then(persistedState => {
  const preloadedState = {
    ...defaultState,
    isLoggedIn: false,
    ...persistedState
  };

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable no-underscore-dangle */

  const enhancers = composeEnhancers(applyMiddleware(thunk));
  const store = createStore(RootReducer, preloadedState, enhancers);

  store.subscribe(
    _throttle(() => {
      saveState(store.getState());
    })
  );

  const AppContainer = () => (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );

  ReactDOM.render(<AppContainer />, document.getElementById('root'));
  registerServiceWorker();
});
