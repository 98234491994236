import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { S3_BUCKET_URL } from '../../lib/constants';
import { uploadTrainingPhotoBase64 } from '../../actionCreators';
import PhotoCapture from '../../components/common/PhotoCapture';

class TrainingPhotoRouter extends Component {
  handleCapturedPhoto = photoBase64 => {
    return this.props.uploadTrainingPhotoBase64(
      this.props.candidate.id,
      this.props.testSessionId,
      photoBase64,
      'image/png'
    );
  };

  render() {
    const { props } = this;
    const combinedTestSessionIds = [];

    if (props.testSessionId) {
      combinedTestSessionIds.push(parseInt(props.testSessionId, 10));
    }

    if (props.counterpartId) {
      combinedTestSessionIds.push(props.counterpartId);
    }

    let trainingPhotos = [];

    if (props.candidate.trainingSessions.length > 0) {
      const trainingSession = props.candidate.trainingSessions.filter(
        trainingSession => combinedTestSessionIds.includes(trainingSession.test_session_id) && !trainingSession.end_time
      )[0];

      if (trainingSession && trainingSession.trainingPhotos.length > 0) {
        trainingPhotos = trainingSession.trainingPhotos;
      }
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PhotoCapture handleCapturedPhoto={this.handleCapturedPhoto} />
        {trainingPhotos.length > 0 && (
          <div style={{ marginTop: '20px', width: '100%' }}>
            <h4>Training Photos</h4>
            {trainingPhotos.map(trainingPhoto => (
              <div key={trainingPhoto.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <a
                  className="button is-link"
                  href={`${S3_BUCKET_URL}${trainingPhoto.s3_key}`}
                  download
                  style={{ marginRight: '20px' }}
                >
                  Download&nbsp;
                  <i className="fa fa-download" aria-hidden="true" />
                </a>
                <span style={{ fontStyle: 'italic' }}>
                  {moment.tz(trainingPhoto.created_at, 'America/Los_Angeles').format('M-D-YYYY h:mm a z')}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadTrainingPhotoBase64
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(TrainingPhotoRouter);
