import React, { Component } from 'react';
import ExamSection from './ExamSection';
import { getDeclinedTest } from '../../../lib/candidate';

class DeclinedTestSelectPage extends Component {
  handleButtonClick = e => {
    this.props.history.push(`${this.props.match.url}/confirm/${e.currentTarget.dataset.crane}`);
  };

  render() {
    const { props } = this;
    const { practicalFXEnabled, practicalSWEnabled } = props.candidate.applicationFormSetup;
    const declinedFX = getDeclinedTest(props.candidate.declinedTests, 'fx');
    const declinedSW = getDeclinedTest(props.candidate.declinedTests, 'sw');
    const FXDisabled = !practicalFXEnabled || !!declinedFX;
    const SWDisabled = !practicalSWEnabled || !!declinedSW;

    return (
      <section>
        <div style={{ marginBottom: '8px' }}>Which exam is {props.candidate.name} declining to take?</div>
        <ExamSection
          crane="fx"
          examName="Fixed Cab Exam"
          examEnabled={practicalFXEnabled}
          declinedTest={declinedFX}
          disabled={FXDisabled}
          handleButtonClick={this.handleButtonClick}
          style={{ marginBottom: '16px' }}
        />
        <ExamSection
          crane="sw"
          examName="Swing Cab Exam"
          examEnabled={practicalSWEnabled}
          declinedTest={declinedSW}
          disabled={SWDisabled}
          handleButtonClick={this.handleButtonClick}
        />
      </section>
    );
  }
}

export default DeclinedTestSelectPage;
