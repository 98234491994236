import React from 'react';
import { Field } from 'formik';
import TextField from '../../../components/form/TextField';

const LineItem = ({ index, arrayHelpers }) => (
  <tr>
    <td>
      <div>
        <Field name={`lineItems[${index}]description`} component={TextField} />
      </div>
    </td>
    <td>
      <div>
        <Field name={`lineItems[${index}]amount`} type="number" component={TextField} />
      </div>
    </td>
    <td style={{ verticalAlign: 'middle', border: 'none' }}>
      <div>
        <button className={`button is-danger`} onClick={() => arrayHelpers.remove(index)}>
          <i className="fa fa-minus" aria-hidden="true" />
          &nbsp;Remove
        </button>
      </div>
    </td>
  </tr>
);

export default LineItem;
