import React, { Component, Fragment } from 'react';
import { S3_BUCKET_URL } from '../../../lib/constants';

class ExamSection extends Component {
  state = {
    attestationVisible: false
  };

  toggleAttestation = () => {
    this.setState({
      attestationVisible: !this.state.attestationVisible
    });
  };

  render() {
    const { crane, examName, examEnabled, declinedTest, disabled, handleButtonClick, style } = this.props;
    return (
      <div style={style}>
        <div>
          <button
            className="button is-link"
            disabled={disabled}
            onClick={handleButtonClick}
            data-crane={crane}
            style={{ marginBottom: '4px', marginRight: '8px', width: '140px' }}
            type="button"
          >
            {examName}
          </button>
          {declinedTest && (
            <Fragment>
              <button
                className="button is-link"
                onClick={this.toggleAttestation}
                style={{ marginBottom: '4px', marginRight: '8px', width: '60px' }}
                type="button"
              >
                {this.state.attestationVisible ? 'Hide' : 'Show'}
              </button>
              <button className="button is-danger" type="button">
                <i className="fa fa-trash" aria-hidden="true" />
              </button>
            </Fragment>
          )}
        </div>
        {!examEnabled && <div style={{ fontSize: '0.8em' }}>Candidate is not registered for {examName}</div>}
        {declinedTest &&
          this.state.attestationVisible && (
            <div>
              <div style={{ border: 'solid 1px #000' }}>
                <img
                  src={`${S3_BUCKET_URL}${declinedTest.s3_key}`}
                  alt={`Declined ${examName} Exam attestation`}
                  style={{ width: '100%', height: '400px' }}
                />
              </div>
              <div style={{ fontSize: '0.8em' }}>Candidate already declined to take {examName}</div>
            </div>
          )}
      </div>
    );
  }
}

export default ExamSection;
