import React from 'react';

const ToggleField = ({ field, label, style, inputStyle }) => (
  <div className="field" style={{ display: 'flex', alignItems: 'center', ...style }}>
    <input
      id={field.name}
      type="checkbox"
      className="switch is-rounded is-info"
      checked={field.value}
      {...field}
      style={{ marginRight: '4px', ...inputStyle }}
    />
    <label htmlFor={field.name} style={{ fontWeight: 'bold' }}>
      {label}
    </label>
  </div>
);

export default ToggleField;
